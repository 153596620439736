import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/analytics';
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyB1K0lHPdIQqmBCrh3pnhR-kQ4HcslaO2A",
  authDomain: "blackcat-fab11.firebaseapp.com",
  projectId: "blackcat-fab11",
  storageBucket: "blackcat-fab11.appspot.com",
  messagingSenderId: "300974980911",
  appId: "1:300974980911:web:8db206aba2feb0596e2bda",
  measurementId: "G-7WL2BSV227"
};

const app = firebase.initializeApp(firebaseConfig);
const db = app.firestore();
const storage = getStorage(app);
const analytics = app.analytics();
export { db, storage, analytics, firebase };