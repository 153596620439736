// Countdown.js
import React, { useState, useEffect } from 'react';

function Countdown({ endTime }) {
  const [timeLeft, setTimeLeft] = useState({ days: '0', hours: '00', minutes: '00', seconds: '00' });

  useEffect(() => {
    const calculateTimeLeft = () => {
      const now = Date.now();
      const timeDiff = endTime - now;
      if (timeDiff <= 0) {
        return { days: '0', hours: '00', minutes: '00', seconds: '00' };
      }
      const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
      const hours = String(Math.floor((timeDiff / (1000 * 60 * 60)) % 24)).padStart(2, '0');
      const minutes = String(Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60))).padStart(2, '0');
      const seconds = String(Math.floor((timeDiff % (1000 * 60)) / 1000)).padStart(2, '0');
      return { days, hours, minutes, seconds };
    };

    setTimeLeft(calculateTimeLeft());
    const interval = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => clearInterval(interval);
  }, [endTime]);

  return (
    <div className="countdown-container">
      <div className="countdown">
        <span className="time-label">RANK END : </span>
        <span className="time">{timeLeft.days}</span>
        <span className="time-label">DAYS</span>
        <span className="time">{timeLeft.hours}</span>
        <span className="time-label">HOURS</span>
        <span className="time">{timeLeft.minutes}</span>
        <span className="time-label">MINS</span>
        <span className="time">{timeLeft.seconds}</span>
        <span className="time-label">SEC</span>
      </div>
    </div>
  );
}

export default React.memo(Countdown);
