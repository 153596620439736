import React, { useCallback, useEffect, useState, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import anime from 'animejs';
import './Starter.css';
import DayToNightToggle from './components/DayNightToggle';
import Fireworks from './components/Firework';
import { DotLottiePlayer } from '@dotlottie/react-player';

const Starter = React.memo(({ consecutiveDays, dayNight, showDayNightChange, showConsecutiveDays }) => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(0);
  const pagesRef = useRef(null);
  const moreThanOnePage = showDayNightChange && showConsecutiveDays;
  const isNight = dayNight === 'night';
  const [animatedDays, setAnimatedDays] = useState(0); // 新增狀態
  const [hasAnimated, setHasAnimated] = useState(false); // 新增狀態

  useEffect(() => {
  }, [showDayNightChange, showConsecutiveDays]); // 添加依賴

  useEffect(() => {
    const ConsecutivePage = showDayNightChange ? 1 : 0;
    if (currentPage === ConsecutivePage && showConsecutiveDays && !hasAnimated) {
      let duration = Math.min(2000 + (consecutiveDays - 1) * 100, 5000); // 使用 Math.min 簡化
      anime({
        targets: { value: 0 },
        value: consecutiveDays,
        round: 1,
        easing: 'easeInOutQuart',
        duration: duration,
        update: (anim) => {
          setAnimatedDays(anim.animations[0].currentValue);
        },
        complete: () => {
          setHasAnimated(true);
        },
      });
    }
  }, [currentPage, consecutiveDays, showConsecutiveDays, hasAnimated, showDayNightChange]); // 確保所有依賴都在

  const getEncouragement = useCallback((days) => {
    if (days < 3) return "Great start! Keep it up!";
    if (days < 7) return "Impressive streak! You're building a habit!";
    if (days < 15) return "Amazing dedication! You're a true regular!";
    if (days < 30) return "very Amazing! You're a the o";
    return "Incredible! You're a Toncat legend!";
  }, []);

  const pageCount = useMemo(() => {
    return (showDayNightChange ? 1 : 0) + (showConsecutiveDays ? 1 : 0); // 簡化計算
  }, [showDayNightChange, showConsecutiveDays]);

  const handlePageChange = useCallback((pageIndex) => {
    if (pageIndex < 0 || pageIndex >= pageCount) return;
    setCurrentPage(pageIndex);
    anime({
      targets: `.starter-page-${pageIndex}`,
      opacity: [0, 1],
      duration: 1000,
      easing: 'easeInOutQuad',
    });
  }, [pageCount]);

  const handlers = useSwipeable({
    onSwipedLeft: () =>  currentPage < pageCount - 1 && handlePageChange(currentPage + 1),
    onSwipedRight: () => currentPage > 0 && handlePageChange(currentPage - 1),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const renderConsecutiveDays = () => {
    return (
      <div>
        <img
          src={dayNight === 'day' ? "/asset/Icon_mw_cat.png" : "/asset/Icon_mb_cat.png"}
          alt="Cat"
          className="starter-image"
        />
        <h2 className="starter-title">
          You've logged in for {consecutiveDays} days!
        </h2>
        <div className="starter-days">
          {animatedDays}
        </div>
        <p className="starter-encouragement">
          {getEncouragement(consecutiveDays)}
        </p>

        <button
          className="starter-start-button"
          onClick={() => navigate('/main')}>
          Start
        </button>
      </div>
    );
  };

  const renderDayNightChange = () => {
    return (
      <>
        <div className="dotLottie">
         <DotLottiePlayer
            src={`${process.env.PUBLIC_URL}/asset/lottie/${isNight ? 'ToTheMoon_s' : 'ToTheSun_s'}.lottie`}
            loop
            autoplay
          />
        </div>
        <div className="DayToNightToggle">
        <DayToNightToggle dayToNight={isNight} />
        </div>
        {!moreThanOnePage && (
          <button
            className="starter-start-button"
            onClick={() => navigate('/main')}>
            Start
          </button>
        )}
      </>
    );
  };

  return (
    <>
      <div className="starter-modal" {...handlers}>
        <div className="starter-container">
          <div className="starter-pages" ref={pagesRef} style={{ transform: `translateX(-${currentPage * 100}%)` }}>

            {/* 第一頁 */}
            {showDayNightChange && (
              <div className="starter-page starter-page-0">
                {renderDayNightChange()}
              </div>
            )}

            {/* 第二頁 */}
            {showConsecutiveDays && (
              <div className="starter-page starter-page-1">
                {renderConsecutiveDays()}
              </div>
            )}
          </div>

          <div className="page-indicator">
            {[0, moreThanOnePage ? 1 : null].filter(pageIndex => pageIndex !== null).map(pageIndex => (
              <span
                key={pageIndex}
                className={`page-dot ${currentPage === pageIndex ? 'active' : ''}`}
                onClick={() => handlePageChange(pageIndex)}
              />
            ))}
          </div>
        </div>
      </div>
      {hasAnimated && <Fireworks animatedDays={animatedDays} />} {/* 傳遞 animatedDays 作為 prop */}
    </>
  );
});

export default Starter;
