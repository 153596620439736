// import firebase.js 配置文件
import { db } from "./firebase";
import { doc, setDoc, collection } from "firebase/firestore"; 

async function generateUsers() {
  const usersCollection = collection(db, "users");

  for (let i = 1; i <= 100; i++) {
    // 格式化文件 ID 為四位數，例如 "0001"
    const id = i.toString().padStart(4, "0");
    const selectedCharacter = Math.floor(Math.random() * 3) + 1;
    // 隨機生成用戶數據
    const userData = {
      dayNight: Math.random() > 0.5 ? "day" : "night",
      first_name: "Testing",
      last_name: id,
      maxCombos: Math.floor(Math.random() * 100) + 1,
      points: Math.floor(Math.random() * (50000 - 100 + 1)) + 100,
      selectedCharacter: selectedCharacter,
      skin: Math.floor(Math.random() * 4) + 101 + selectedCharacter*100 - 100,
      username: `Testing${id}`,
    };

    // 寫入到 Firebase Firestore
    try {
      await setDoc(doc(usersCollection, id), userData);
      console.log(`User ${id} added successfully.`);
    } catch (error) {
      console.error(`Error adding user ${id}:`, error);
    }
  }
}
export default generateUsers;
