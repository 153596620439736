import React, { useState, useEffect, useCallback, useRef } from 'react';
import anime from 'animejs';
import './ScoreBoard.css';  // 新增這一行
import { DotLottiePlayer } from '@dotlottie/react-player';

function ScoreBoard({ combos, maxCombos, todayGamePoints, todayGamePointsReached, points, pendingGamePoints, timeLeft ,userColorScheme }) {
  const [showCombos, setShowCombos] = useState(false);
  const combosTierRef = useRef(0)
  const hideTimerRef = useRef(null);
  const minDisplayTimerRef = useRef(null);
  const prevCombosRef = useRef(combos);
  const prevPendingGamePointsRef = useRef(0);

  const pendingPointsAnimation = useCallback((lastPendingPoints) => {
    document.querySelector(".points-animation").textContent = `+${lastPendingPoints}`;
    anime.timeline()
      .add({
        targets: [".points-animation"],
        translateY: [{ duration: 0, value: "0" }],
        easing: "linear",
        filter: [{ duration: 0, value: 'opacity(100%)' }],
        
      })
      .add({
        targets: [".points-animation"],
        translateY: [{ duration: 1000, value: "-50" }],
        filter: [{ duration: 1000, value: 'opacity(0%)' }],
        easing: "linear",
      });
  }, []);

  const HideComboTimerAnime = () => {
    anime({
        targets: [".combos-container"],
        opacity: [{ duration: 500, value: "0" }],
        scale: [{ duration: 500, value: "1" }],
        easing: "linear",
    });
    anime({
      targets: [".combos-container"],
      color: '#ffffff',
  });
};
const ShowComboTimerAnime = () => {
  anime({
      targets: [".combos-container"],
      opacity: [{ duration: 200, value: "1" }],
      easing: "linear",
  });
};
  const hideComboTimer = useCallback(() => {
    if (hideTimerRef.current) {
      clearTimeout(hideTimerRef.current);
    }
    hideTimerRef.current = setTimeout(() => {
      if (minDisplayTimerRef.current) {
        clearTimeout(minDisplayTimerRef.current);
      }
      HideComboTimerAnime();
      setShowCombos(false);
    }, 500);
  }, []);

  useEffect(() => {
    if (pendingGamePoints === 0 && prevPendingGamePointsRef.current !== 0) {
      pendingPointsAnimation(prevPendingGamePointsRef.current);
    } else {
      prevPendingGamePointsRef.current = pendingGamePoints;
    }
  }, [pendingGamePoints, pendingPointsAnimation]);

  useEffect(() => {
    if (combos > prevCombosRef.current) {
      ShowComboTimerAnime();
      setShowCombos(true);
      
      if (minDisplayTimerRef.current) {
        clearTimeout(minDisplayTimerRef.current);
      }
      minDisplayTimerRef.current = setTimeout(() => {
        hideComboTimer();
      }, 5000);

      if (hideTimerRef.current) {
        clearTimeout(hideTimerRef.current);
      }
    }
    prevCombosRef.current = combos;
  }, [combos, hideComboTimer]);

  useEffect(() => {
    return () => {
      if (hideTimerRef.current) {
        clearTimeout(hideTimerRef.current);
      }
      if (minDisplayTimerRef.current) {
        clearTimeout(minDisplayTimerRef.current);
      }
    };
  }, []);

  const CombosColor = ['#ffffff','#b4d95f','#62cc41','#ff751f','#e64629']

  const CombosTierUp = (tier) => {
    if(combosTierRef.current!==tier)
    {
    combosTierRef.current = tier;
    const targetScale = 1+tier*0.1;
    anime({
        targets: [".combos-container"],
        scale: [{ duration: 100, value: `${targetScale}` }],
        transform: "translate(-50%,-50%)",
        easing: "easeInCubic",
    });
    anime({
      targets: [".combos-container"],
      color: [{ duration: 100, value: CombosColor[tier] }],
      easing: "easeInQuint",
  });
  }
};

  // 根據 combos 值選擇火焰效果
  let fireGif = null;
  if (combos >= 30) {
    fireGif = 'asset/gif/fire4.gif';  // 第四階段火焰
    CombosTierUp(4);
  } else if (combos >= 20) {
    fireGif = 'asset/gif/fire3.gif';  // 第三階段火焰
    CombosTierUp(3);
  } else if (combos >= 10) {
    fireGif = 'asset/gif/fire2.gif';  // 第二階段火焰
    CombosTierUp(2);
  } else if (combos >= 5) {
    fireGif = 'asset/gif/fire1.gif';  // 第一階段火焰
    CombosTierUp(1);
  }

  useEffect(() => {
    //HideComboTimerAnime();
  }, []);
  

  return (
    <div className="main-content">
      <div className="combos-container">
        <div className="combos-value">
          {combos}
        </div>
        <div className="combos-text">COMBO</div>
        {showCombos && (
          <div className="fire-effect">
            
          </div>
        )}
        <div className="max-combos-container">
          <span className="max-combos-label">MAX</span>
          <span className="max-combos-value">{maxCombos}</span>
        </div>
      </div>
      <div className="progress-container">        
        <div className="maxReached-container">         
          {todayGamePointsReached && (
            <div>
              <div></div>
              <div className="countdown-timer"style={{color:userColorScheme === 'light' ? '#000000' : '#FFFFFF'}}>
              ↻ {`${timeLeft.hours}:${timeLeft.minutes}:${timeLeft.seconds}`}
              </div>
            </div>
          )}
        </div>
        <div className="progress-bar-container">
          <svg width="75%" height="3vh">
            <rect width="100%" height="100%" fill="rgba(0, 0, 0, 0.5)" rx="1.5vh" ry="1.5vh" />
            <rect 
              width={`${todayGamePoints / 500 * 100}%`} 
              height="3vh" 
              fill={`rgb(${255 - (todayGamePoints / 500) * 255}, ${255 - (todayGamePoints / 500) * 137}, ${255 - (todayGamePoints / 500) * 255})`} 
              rx="1.5vh" 
              ry="1.5vh"
              style={{ transition: 'width 0.5s ease' }} 
            />
            <text 
              x="50%" 
              y="50%" 
              fill='#FFFFFF'
              textAnchor="middle" 
              fontSize="2vh" 
              dominantBaseline="middle" 
              alignmentBaseline="middle"
            >
              {`Progress: ${todayGamePoints} / 500`}
            </text>
          </svg>
        </div>
      </div>
      <div className="main-points-container">
        <div className="main-points-text">Your $CATZ:</div>
        {pendingGamePoints > 0 && !todayGamePointsReached && (
          <div className="pending-points">+{pendingGamePoints}</div>
        )}
          <div className="points-animation">+{pendingGamePoints}</div>

          
        <div className="main-points-value">
          {points}<img alt="coin" src={`${process.env.PUBLIC_URL}/asset/Catz_s.webp`} style={{ width: '10vw', height: 'auto' }}/>
        </div>
      </div>
    </div>
  );
}

export default ScoreBoard;
