import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import './WelcomePage.css';
import useSound from 'use-sound'; // 导入 useSound 钩子
import { db } from './firebase';
import { DotLottiePlayer,PlayerEvents } from '@dotlottie/react-player';
import { FaTelegram } from 'react-icons/fa';
const buttonClickSound = `${process.env.PUBLIC_URL}/asset/click2.mp3`; // 更正导入路径

const WelcomePage = React.memo(({ inviterID }) => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(0);
  const [inviterFullName, setInviterFullName] = useState('');
  const pagesRef = useRef(null);
  const [showDayNightToggle, setShowDayNightToggle] = useState(false);
  const [playButtonSound] = useSound(buttonClickSound, { volume: 0.5 }); // 创建音效播放函数
  const [isAnimationComplete, setIsAnimationComplete] = useState(false); // 新增状态
  const [activePage, setActivePage] = useState(0); // 新增状态
  const pageRefs = useRef([]); // 创建一个数组来存储每个 DotLottiePlayer 的引用

  const isNightMode = window.Telegram.WebApp.colorScheme=== 'dark' ? true : false;


  const handleStart = useCallback(() => {
    playButtonSound(); // 播放按钮音效
    if (window.Telegram && window.Telegram.WebApp) {
      window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
    }
    navigate('/main');
  }, [playButtonSound,navigate]);
  
  useEffect(() => {
    if (inviterID !== -1) {
      const fetchInviterName = async () => {
        const inviterRef = db.collection('users').doc(String(inviterID)); // 确保 inviterID 是字符串
        const inviterDoc = await inviterRef.get();
        if (inviterDoc.exists) {
          const inviterData = inviterDoc.data();
          const inviterName = (inviterData.first_name || '') + ' ' + (inviterData.last_name || '');
          setInviterFullName(inviterName);
        }
      };
      fetchInviterName();
    }
  }, [inviterID]);

  useEffect(() => {
    if (currentPage === 1 ) {
      setShowDayNightToggle(true);
    }
  }, [currentPage, isNightMode]);

  const handlePageChange = useCallback((pageIndex) => {
    setCurrentPage(pageIndex);
    setActivePage(pageIndex); // 更新 activePage 状态


    // 调用当前页面的动画播放
    if (pageRefs.current[pageIndex]) {
      pageRefs.current[pageIndex].play();
    }
  }, []);

  const pageCount = useMemo(() => {
    let count = 3;
    if (inviterID !== -1) count += 1;
    return count; // 确保包括最后一页
  }, [inviterID]);

  const handlers = useSwipeable({
    onSwipedLeft: () =>  currentPage < pageCount && handlePageChange(currentPage + 1),
    onSwipedRight: () => currentPage > 0 && handlePageChange(currentPage - 1),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });

  return (
    <div className="welcome-modal" {...(isAnimationComplete ? handlers : {})}>
      <div className="welcome-container">
        <div className="welcome-pages" ref={pagesRef} style={{ transform: `translateX(-${currentPage * 100}%)` }}>

          {/* 第一頁 */}
          <div className="welcome-page welcome-page-0">
            <DotLottiePlayer
              ref={(el) => (pageRefs.current[0] = el)} // 存储引用
              src={`${process.env.PUBLIC_URL}/asset/lottie/CheckCatType_${isNightMode?'Black':'White'}.lottie`}
              onEvent={(event) => {
                if (event === PlayerEvents.Ready) {
                  setTimeout(() => {
                    setIsAnimationComplete(true);
                  }, 4000);
                }
              }}
              autoplay // 移除 autoplay
            >
            </DotLottiePlayer>
          </div>



          {/* 第二頁 */}
          <div className="welcome-page welcome-page-1">
              <DotLottiePlayer
                ref={(el) => (pageRefs.current[1] = el)}
                src={`${process.env.PUBLIC_URL}/asset/lottie/Tutorial_AddFriend.lottie`}
                loop
                className="animation-container"
              />
          </div>

          {/* 第二頁 */}
          <div className="welcome-page welcome-page-2">
          <DotLottiePlayer
            ref={(el) => (pageRefs.current[2] = el)} // 存储引用
            src={`${process.env.PUBLIC_URL}/asset/lottie/Tutorial_PlayWithFriend.lottie`}
            loop
            className="animation-container"
            // autoplay={activePage === 3} // 移除 autoplay
          />
          </div>

          {/* 第三頁 */}
          {inviterID !== -1 && (
            <div className="welcome-page welcome-page-3">
              <div className="welcome-content">
                <div className="inviter">
                <FaTelegram className="telegram-icon" /> 
                {inviterFullName}
                </div>
              </div>
              
              <img 
                src={`${process.env.PUBLIC_URL}/asset/YouGotAFriend.png`}
                alt="Black Cat" 
                className="animation-container"
              />
            </div>
          )}

          {/* 第四頁 */}
          <div className="welcome-page welcome-page-4">

          <DotLottiePlayer
            ref={(el) => (pageRefs.current[inviterID !== -1 ? 4 : 3] = el)} // 存储引用
            src={`${process.env.PUBLIC_URL}/asset/lottie/${isNightMode ? 'TOTHEMOON' : 'TOTHESUN'}.lottie`}
            loop
            style={{ width: '100%', height: 'auto' }}
            // autoplay={activePage === 1} // 移除 autoplay
          />
            <button className="start-button" onClick={handleStart}>Start</button>
          </div>
        </div>
        {isAnimationComplete && 
        (<div className="page-indicator">
          {[0, 1,  2, 3 , inviterID !== -1 ? 4 : null].filter(pageIndex => pageIndex !== null).map(pageIndex => (
            <span
              key={pageIndex}
              className={`page-dot ${currentPage === pageIndex ? 'active' : ''}`}
              onClick={() => handlePageChange(pageIndex)}
            />
          ))}
        </div>)}
      </div>
    </div>
    
  );
});

export default WelcomePage;
