import React, { useEffect, useRef } from "react";
import anime from "animejs/lib/anime.es.js";
import "./DayNightToggle.css"; // 放入 CSS 的地方

const DayNightToggle =React.memo(({dayToNight}) => {
  const svgRef = useRef(null);
  
  useEffect(() => {
    const select = (s) => svgRef.current.querySelector(s);
    const selectAll = (s) => svgRef.current.querySelectorAll(s);

    const allStars = selectAll(".starGroup *");
    const allClouds = selectAll(".cloud");

    // 初始化動畫可見性
    svgRef.current.style.visibility = "visible";

    // 初始化星星和雲朵
    allStars.forEach((star) => {
      star.style.transformOrigin = "50% 50%";
    });

    allClouds.forEach((cloud) => {
      cloud.style.opacity = 1;
    });

    // 動畫時間線
    const timeline = anime.timeline({
      autoplay: false,
      easing: 'easeInOutQuad',
    });

    timeline
    .add({        
      duration: 1000,
    })
      .add({
        targets: [select('.sun'), select('.moonMask')],
        translateX: '-=140',
        translateY: '-=20',
        duration: 1000,
      })
      .add({
        targets: [select('.moon')],
        translateX: '-=100',
        duration: 1000,
        opacity: 1,        
      },'-=1000')
      .add({
        targets: select('.sun'),
        opacity: 0,
        duration: 1000,
        offset: '-=1000',
      },'-=1000')      
      .add({
        targets: '.outline',
        stroke: '#6172AD',
        fill: '#45568D',
        duration: 1000,
        offset: '-=1000',
      },'-=1000')
      .add({
        targets: allStars,
        translateX: (el, i) => [ -20, 30, 40, -30, 60, -40, 80, 90, 100, 110, 120 ][i % 11],
        opacity: 1,
        duration: 900,
        delay: anime.stagger(10),
        offset: '-=1000',
      },'-=1000')
      .add({
        targets: allClouds,
        translateX: 40,
        opacity: 1,
        duration: 1000,
        offset: '-=1000',
      },'-=1000')
      .add({        
        duration: 1000,
      })
      .add({
        targets: [select('.sun'), select('.moonMask')],
        translateX: '+=140',
        translateY: '+=20',
        duration: 1000,
      })
      .add({
        targets: [select('.moon')],
        translateX: '+=100',
        duration: 1000,
        opacity: 0,
        offset: '-=1000',
      },'-=1000')
      .add({
        targets: select('.sun'),
        opacity: 1,
        duration: 1000,
        offset: '-=1000',
      },'-=1000')      
      .add({
        targets: '.outline',
        stroke: '#FCFDFE',
        fill: '#85E8FE',
        duration: 1000,
        offset: '-=1000',
      },'-=1000')
      .add({
        targets: allStars,
        opacity: 0,
        duration: 1000,
        offset: '-=1000',
      },'-=1000')
      .add({
        targets: allClouds,
        translateY: 0,
        opacity: 1,
        duration: 600,
        delay: anime.stagger(60),
        offset: '-=1000',
      },'-=1000')
      .add({
        targets: ".plane",
        translateX: 400,
        duration: 700,
      })
      .add({
        targets: ".contrail",
        opacity: 0,
        duration: 500,
      });

      if(dayToNight)
      {
        timeline.play();
  setTimeout(() => {
    timeline.pause();
  }, 2000);
      }
      else
      {
        timeline.seek(2000); // 將動畫設置到500毫秒的位置
        timeline.play();
      }    
  }, [dayToNight]);

  return (
    <>
    <svg ref={svgRef} xmlns="http://www.w3.org/2000/svg" viewBox="0 200 800 200">  
  <defs>
    <filter id="shadow" x="-100%" y="-100%" width="250%" height="250%">
      <feGaussianBlur stdDeviation="4" result="coloredBlur" />
      <feOffset dx="0" dy="0" result="offsetblur"></feOffset>
      <feFlood floodColor="#000" floodOpacity="0.07"></feFlood>
      <feComposite in2="offsetblur" operator="in"></feComposite>
      <feMerge>
        <feMergeNode />
        <feMergeNode in="SourceGraphic"></feMergeNode>
      </feMerge>
    </filter>
    <mask id="moonMask">
      <rect id="outlineFill" x="225" y="215" width="350" height="170" rx="86" ry="86" fill="#FFF" stroke="#000" strokeWidth="0" />
      <circle className ="moonMask" cx="487" cy="300" r="66" fill="#06070B" />
    </mask>
    <clipPath id="outlineMask">
      <use xlinkHref="#outlineFill" />
    </clipPath>
    <path id="cloud" d="M20.67,42.52h39c22,0,18-16.52,11.19-17.51,1.93-7.5-1.94-25-23.19-25s-24,18-24,18S12.75,13.2,5,18.83C-3.8,25.15-2.33,42.52,20.67,42.52Z" />
  </defs>
  <g className ="whole">
    <rect className ="outline" x="220" y="210" width="360" height="180" rx="90" ry="90" fill="#85E8FE" stroke="#FCFDFE" strokeWidth="10" />
    <g className ="starGroup" fill="#FFF" clipPath="url(#outlineMask)">
      <polygon points="405 248.25 406.58 254.42 412.75 256 406.58 257.58 405 263.75 403.42 257.58 397.25 256 403.42 254.42 405 248.25" />
      <path d="M427.33,301.9a1.5,1.5,0,0,0,0-3,1.5,1.5,0,0,0,0,3Z" />
      <path d="M402.59,367.21a1.5,1.5,0,0,0,0-3,1.5,1.5,0,0,0,0,3Z" />
      <polygon points="447 325.5 450.17 337.83 462.5 341 450.17 344.17 447 356.5 443.83 344.17 431.5 341 443.83 337.83 447 325.5" />
      <path d="M464,241.53a1.5,1.5,0,0,0,0-3,1.5,1.5,0,0,0,0,3Z" />
      <polygon points="496 274.25 497.58 280.42 503.75 282 497.58 283.58 496 289.75 494.42 283.58 488.25 282 494.42 280.42 496 274.25" />
      <path d="M503.53,351.38a1.5,1.5,0,0,0,0-3,1.5,1.5,0,0,0,0,3Z" />
      <path d="M537.18,249.45a1.5,1.5,0,0,0,0-3,1.5,1.5,0,0,0,0,3Z" />
      <path d="M553,322.68a1.5,1.5,0,0,0,0-3,1.5,1.5,0,0,0,0,3Z" />
    </g>
    <circle className ="sun" cx="486" cy="300" r="66" fill="#FEC100" />
    <g mask="url(#moonMask)">
      <circle className ="moon" cx="403" cy="300" r="66" fill="#FFF" opacity="0" />
    </g>    
    <g className ="cloudGroup" fill="#FFF" clipPath="url(#outlineMask)">
      <g className ="cloud" filter="url(#shadow)">
        <rect x="515.79" y="314.92" width="47.15" height="10.37" rx="5.19" ry="5.19" />
        <circle cx="521.45" cy="313.98" r="11.32" />
        <circle cx="540.84" cy="305.91" r="15.61" />
        <circle cx="556.82" cy="316.34" r="8.96" />
      </g>
      <g className ="cloud" filter="url(#shadow)">
        <rect x="397.85" y="349.02" width="92.34" height="10.37" rx="5.19" ry="5.19" transform="translate(888.03 708.41) rotate(180)" />
        <circle cx="484.53" cy="348.08" r="11.32" />
        <circle cx="432.65" cy="332.46" r="24.66" />
        <circle cx="464.35" cy="338.11" r="17.51" />
        <circle cx="404.21" cy="348.19" r="11.32" />
      </g>
    </g>
  </g>
</svg>
</>
  );
});
export default DayNightToggle;