import React from 'react';

function MatterCanvas({ scene }) {
  return (
    <div ref={scene} className="matter-js-scene" 
      style={{ 
        position: 'absolute', 
        height: '85vh',
        zIndex: 100, 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        margin: '0',
        padding: '0',
        top: '0vh'
      }}>
      {/* Matter.js scene */}
    </div>
  );
}

export default MatterCanvas;