import React, { useEffect, useRef } from 'react';
import anime from 'animejs';

const Fireworks = ({ animatedDays }) => { // 接收 animatedDays 作為 prop
  const canvasRef = useRef(null);
  const colors = ['#FF1461', '#18FF92', '#5A87FF', '#FBF38C'];
  const numberOfParticules = 30;

  const setCanvasSize = (canvasEl) => {
    if (canvasEl) {
      canvasEl.width = window.innerWidth * 2;
      canvasEl.height = window.innerHeight * 2;
      canvasEl.style.width = window.innerWidth + 'px';
      canvasEl.style.height = window.innerHeight + 'px';
      const ctx = canvasEl.getContext('2d');
      ctx.scale(2, 2);
    }
  };

  const setParticuleDirection = (p) => {
    const angle = anime.random(0, 360) * Math.PI / 180;
    const value = anime.random(50, 200);
    const radius = [-1, 1][anime.random(0, 1)] * value;
    return {
      x: p.x + radius * Math.cos(angle),
      y: p.y + radius * Math.sin(angle)
    };
  };

  const createParticule = (x, y, ctx) => {
    const p = {};
    p.x = x;
    p.y = y;
    p.color = colors[anime.random(0, colors.length - 1)];
    p.radius = anime.random(10, 20);
    p.endPos = setParticuleDirection(p);
    p.draw = function () {
      ctx.beginPath();
      ctx.arc(p.x, p.y, p.radius, 0, 2 * Math.PI, true);
      ctx.fillStyle = p.color;
      ctx.fill();
    };
    return p;
  };

  const createCircle = (x, y, ctx) => {
    const p = {};
    p.x = x;
    p.y = y;
    p.color = '#FFF';
    p.radius = 0.1;
    p.alpha = 0.5;
    p.lineWidth = 6;
    p.draw = function () {
      ctx.globalAlpha = p.alpha;
      ctx.beginPath();
      ctx.arc(p.x, p.y, p.radius, 0, 2 * Math.PI, true);
      ctx.lineWidth = p.lineWidth;
      ctx.strokeStyle = p.color;
      ctx.stroke();
      ctx.globalAlpha = 1;
    };
    return p;
  };

  const renderParticule = (anim) => {
    anim.animatables.forEach((animatable) => {
      animatable.target.draw();
    });
  };

  const animateParticules = (x, y, ctx) => {
    const circle = createCircle(x, y, ctx);
    const particules = [];
    for (let i = 0; i < numberOfParticules; i++) {
      particules.push(createParticule(x, y, ctx)); // 使用相同的 x, y 座標
    }
    anime.timeline().add({
      targets: particules,
      x: (p) => p.endPos.x,
      y: (p) => p.endPos.y,
      radius: 0.1,
      duration: anime.random(1200, 1800),
      easing: 'easeOutExpo',
      update: renderParticule
    })
    .add({
      targets: circle,
      radius: anime.random(80, 160),
      lineWidth: 0,
      alpha: {
        value: 0,
        easing: 'linear',
        duration: anime.random(600, 800),
      },
      duration: anime.random(1200, 1800),
      easing: 'easeOutExpo',
      update: renderParticule,
      offset: 0 // 確保和 particules 同時執行
    },'-=1600');
  };

  useEffect(() => {
    const canvasEl = canvasRef.current;
    const ctx = canvasEl.getContext('2d');

    setCanvasSize(canvasEl);

    const render = anime({
      duration: Infinity,
      update: () => {
        ctx.clearRect(0, 0, canvasEl.width, canvasEl.height);
      }
    });

    const autoGenerateParticules = () => {
      const x = anime.random(0, window.innerWidth);
      const y = anime.random(0, window.innerHeight);
      animateParticules(x, y, ctx);
    };

    let intervalId;
    let fireworksCount = 0;
    const maxFireworksCount = animatedDays >= 30 ? 40 : animatedDays >= 15 ? 25 : animatedDays >= 7 ? 15 : 10;
    const fireworksInterval = 2500 / maxFireworksCount;

    if (animatedDays > 3) {
      intervalId = setInterval(() => {
        if (fireworksCount < maxFireworksCount) {
          autoGenerateParticules();
          fireworksCount++;
        } else {
          clearInterval(intervalId);
        }
      }, fireworksInterval);
    }

    window.addEventListener('resize', () => setCanvasSize(canvasEl), false);

    return () => {
      clearInterval(intervalId);
      window.removeEventListener('resize', () => setCanvasSize(canvasEl));
    };
  }, [animatedDays]);

  return <canvas ref={canvasRef} className="fireworks" />;
};

export default Fireworks;
