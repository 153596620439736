import React, { useState, useEffect, useCallback, useMemo , useRef } from 'react';
import { useLocation } from 'react-router-dom';
import './Friend.css';
import { db } from './firebase';
import Loader from './Loader';
import { skin } from './CharacterDatabase';
import { ref, uploadBytes, getDownloadURL,  } from 'firebase/storage';
import { storage } from './firebase';
import { FaCopy } from 'react-icons/fa';
import useSound from 'use-sound';
import html2canvas from 'html2canvas';

import generateUsers from "./DevFunctions";

const Friend = React.memo(({}) => {
  const location = useLocation();
  const story = location.state?.story;
  const [friends, setFriends] = useState([]);
  const [selectedFriends, setSelectedFriends] = useState([]);
  const [message, setMessage] = useState('');
  const [showApplyButton, setShowApplyButton] = useState(false);
  const [currentSelectedFriends, setCurrentSelectedFriends] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [userIcon, setUserIcon] = useState('');
  const [playClickSound] = useSound(`${process.env.PUBLIC_URL}/asset/click.mp3`, { volume: 0.5 });
  const [showStoryMaker, setShowStoryMaker] = useState(false);
  const contentRef = useRef(null);
  const [isSharing, setIsSharing] = useState(false);
  const [debug, setDebug] = useState(false);
  const [userData, setUserData] = useState([]);
  const haveFriend = friends.length>0;  
  const [makeUpFriendList, setMakeUpFriendList] = useState([]);
  const [randomUserIcon, setRandomUserIcon] = useState('');

  useEffect(() => {
    const fetchFriends = async () => {
      setIsLoading(true);      
      const userId = window.Telegram.WebApp.initDataUnsafe.user.id.toString();
      const userRef = db.collection('users').doc(userId);
      console.log("Try access firebase(Read)")
      const userDoc = await userRef.get();
      if (userDoc.exists) {
        const userData = userDoc.data();
        setUserIcon(userData.dayNight === 'day' ? skin.find(skin => skin.id === userData.skin).dayUrl : skin.find(skin => skin.id === userData.skin).nightUrl);
        setUserData(userData);
        const friendIds = userData.friends || [];
        const selectedFriendIds = userData.selected_friends || [];
        const friendPromises = friendIds.map(async (id) => {
          const friendDoc = await db.collection('users').doc(id).get();
          const friendData = friendDoc.data();
          return { 
            ...friendData, 
            id,
            first_name: friendData.first_name || 'Unknown',
            last_name: friendData.last_name || '',
            iconUrl: friendData.dayNight === 'day' ? skin.find(skin => skin.id === friendData.skin).dayUrl : skin.find(skin => skin.id === friendData.skin).nightUrl,
            max_combos: friendData.max_combos || 0,
            isSelected: selectedFriendIds.includes(id) // 確認是否在selected_friends中
          };
        });
        let friendsData = await Promise.all(friendPromises);
        
        // 按 max_combos 降序排序，并只保留前100个
        friendsData = friendsData
          .sort((a, b) => b.max_combos - a.max_combos)
          .slice(0, 100);
        
        setFriends(friendsData);
        setSelectedFriends(selectedFriendIds);
        setCurrentSelectedFriends(selectedFriendIds);
      }
      setIsLoading(false);
    };
    fetchFriends();
  }, []);

  useEffect(() => {
    
    if (currentSelectedFriends !== selectedFriends) {
      setShowApplyButton(false);
    } else {
      setShowApplyButton(true);
    }
  }, [selectedFriends]);

  const showMessage = useCallback((msg, duration = 3000) => {
    setMessage(msg);
    setTimeout(() => setMessage(''), duration);
  }, []);


  const toggleStoryMaker = useCallback(() => {
    setShowStoryMaker(!showStoryMaker);
  }, [showStoryMaker]);

  const handleCheckboxChange = useCallback((friendId) => {
    playClickSound();
    if (window.Telegram && window.Telegram.WebApp) {
      window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
    }
    setSelectedFriends((prevSelected) => {
      let newSelected;
      if (prevSelected.includes(friendId)) {
        newSelected = prevSelected.filter(id => id !== friendId);
      } else if (prevSelected.length < 4) {
        newSelected = [...prevSelected, friendId];
      } else {
        showMessage('You can only select up to 4 friends');
        newSelected = prevSelected;
      }
      setCurrentSelectedFriends(newSelected); // 更新 currentSelectedFriends
      return newSelected;
    });
  }, [showMessage, playClickSound]); // 添加右括号
  

    const applySelection = useCallback(async () => {
      playClickSound();
      if (window.Telegram && window.Telegram.WebApp) {
        window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
      }
      const userId = window.Telegram.WebApp.initDataUnsafe.user.id.toString();
      const userRef = db.collection('users').doc(userId);
      await userRef.update({ selected_friends: selectedFriends });
      showMessage('✔ Updated favorite friends');
      setShowApplyButton(false); // 按下按钮后隐藏
    }, [selectedFriends]);

    const generateInviteLink = useCallback(() => {
      playClickSound();
      if (window.Telegram && window.Telegram.WebApp) {
        window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
      }
      const userId = window.Telegram.WebApp.initDataUnsafe.user.id.toString();
      const inviteLink = `https://t.me/catzgame_bot/CATZ?startapp=inviter_${userId}`;
      const message = `Join my Telegram Mini App: ${inviteLink}`;
    
      if (window.Telegram && window.Telegram.WebApp) {
        window.Telegram.WebApp.openTelegramLink(`https://t.me/share/url?url=${encodeURIComponent(message)}`);
      } else {
        navigator.clipboard.writeText(inviteLink)
          .then(() => showMessage('Invite link copied to clipboard!'))
          .catch(err => {
            showMessage('Error copying text');
          });
      }
    }, [showMessage]);

  const copyInviteLink = useCallback(() => {
    playClickSound();
    if (window.Telegram && window.Telegram.WebApp) {
      window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
    }
    const userId = window.Telegram.WebApp.initDataUnsafe.user.id.toString();
    const inviteLink = `https://t.me/catzgame_bot/CATZ?startapp=inviter_${userId}`;

    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(inviteLink)
        .then(() => showMessage('Invite link copied to clipboard!'))
        .catch(err => {
          showMessage('Error copying text');
        });
    } else {
      const textArea = document.createElement('textarea');
      textArea.value = inviteLink;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        document.execCommand('copy');
        showMessage('Invite link copied to clipboard!');
      } catch (err) {
        showMessage('Error copying text');
      }
      document.body.removeChild(textArea);
    }
  }, [showMessage]);

  const friendList = useMemo(() => (
    friends.map((friend) => {
      const firstName = friend?.first_name || '';
      const lastName = friend?.last_name || '';
      const points = friend?.points || 0;
      const maxCombos = friend?.maxCombos || 0;

      return (
        <li key={friend.id} className="friend-item">
          <img src={friend.iconUrl} alt="Friend Icon" className="friend-avatar" />
          <div className="friend-info">
            <div className="friend-name">{firstName} {lastName}</div>
            <div className="friend-details">
              <div className="friend-details-item">Max Combos: {maxCombos}</div>
              <div className="friend-details-item"> CATS:{points}</div>
            </div>
          </div>
          <input 
            type="checkbox" 
            checked={selectedFriends.includes(friend.id)} 
            onChange={() => handleCheckboxChange(friend.id)} 
          />
        </li>
      );
    })
  ), [friends, selectedFriends, handleCheckboxChange]);

  const bottomPositions = ['6vh', '22vh', '33vh', '37vh'];
  const leftPositions = ['82vw', '75vw', '48vw', '15vw'];
  
  const selectedFriendList = useMemo(() => (
    friends.filter(friend => selectedFriends.includes(friend.id)).map((friend, index) => {
      const firstName = friend?.first_name || '';
      const lastName = friend?.last_name || '';
      const bottomPosition = bottomPositions[index];
      const leftPosition = leftPositions[index];
      return (
        <div key={friend.id}>
          <li className="story-friend-item" style={{ bottom: bottomPosition, left: leftPosition }}>
            <img src={friend.iconUrl} alt="Friend Icon" className="story-friend-avatar" />
            <div className="story-friend-name">{firstName} {lastName}</div>
          </li>
        </div>
      );
    })
  ), [friends, selectedFriends]);

  const generateMakeUpFriendList = () => {
    const TempFriendList = [];
    const randomSkins = [101, 102, 103, 201, 202, 203, 301, 302, 303];

    // 隨機生成用的圖標
    const randomUserSkin = randomSkins[Math.floor(Math.random() * randomSkins.length)];
    const userSkinURL = Math.random() > 0.5 ? skin.find(skin => skin.id === randomUserSkin).dayUrl : skin.find(skin => skin.id === randomUserSkin).nightUrl;
    setRandomUserIcon(userSkinURL);

    for (let i = 0; i < 4; i++) {
      const randomSkin = randomSkins[Math.floor(Math.random() * randomSkins.length)];
      const skinURL = Math.random() > 0.5 ? skin.find(skin => skin.id === randomSkin).dayUrl : skin.find(skin => skin.id === randomSkin).nightUrl;
      const firstName = 'user';
      const lastName = Math.random().toString(36).substring(2, 7); // 生成随机5位字符串
      const bottomPosition = bottomPositions[i];
      const leftPosition = leftPositions[i];
      TempFriendList.push({
        skinURL,
        firstName,
        lastName,
        bottomPosition,
        leftPosition
      });
    }

    setMakeUpFriendList(TempFriendList);
  };

  const RandomSkin = () => {
    setDebug(true);
    generateMakeUpFriendList();
  };

  const handleShareStory = async () => {
    const storyText = `https://t.me/catzgame_bot/CATZ?startapp=inviter_${userData.id}`;
    playClickSound();
    if (window.Telegram && window.Telegram.WebApp) {
      window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
    }
    console.log('isSharing?:'+isSharing);
    if (!contentRef.current || isSharing) return;
    setIsSharing(true);
    try {
      // 將 React 組件轉換為 Canvas
      const canvas = await html2canvas(contentRef.current, {
        useCORS: true,
        scale: 2,
        backgroundColor: '#ffffff'
      });
      // 將 Canvas 轉換為 Blob
      const blob = await new Promise(resolve => canvas.toBlob(resolve, 'image/png'));

      // 生成唯一的文件名
      const fileName = `share_${Date.now()}.png`;

      // 上傳到 Firebase Storage
      const storageRef = ref(storage, fileName);
      await uploadBytes(storageRef, blob);

      // 獲取下載 URL
      const downloadURL = await getDownloadURL(storageRef);

      // 使用 Telegram Mini App API 分享到故事
      if (window.Telegram && window.Telegram.WebApp) {
        window.Telegram.WebApp.shareToStory(downloadURL,{ text: storyText });
      } else {
        console.error('Telegram WebApp API is not available');
      }
    } catch (error) {
      console.error('Error creating or sharing image:', error);
      setIsSharing(false);
    } finally {
      const userId = window.Telegram.WebApp.initDataUnsafe.user.id.toString();
      const userRef = db.collection('users').doc(userId);
      await userRef.update({ sharedStory: true });    
      setIsSharing(false); 
    }
  };

  const team = useMemo(() => (
    <>
      <li className="team-item user-icon">
        <img src={userIcon} alt="User Icon" className="friend-avatar" />
      </li>
      {selectedFriends.map((friendId, index) => {
        const friend = friends.find(f => f.id === friendId);
        return (
          <li key={friend.id} className="team-item">
            <img src={friend.iconUrl} alt="Friend Icon" className="friend-avatar" />
          </li>
        );
      })}
    </>
  ), [userIcon, friends, selectedFriends]);
  
  useEffect(() => {
    console.log(story);
    console.log(selectedFriends);
    if (story === 'Share') {
      setTimeout(() => {
        handleShareStory();
        console.log("Try share story");
      }, 500);
    }
  }, [story,selectedFriends]);

  return (
    <>
      {message && <div className="f-message">{message}</div>}
        <div className="friend-container">
          <div className="friend-list-background">
            <div className="friend-list-container">

              <h1>FRIENDS</h1>
              <p>Invite and get more CATS</p>            
              <div className="team-backgroud"
                style={{
                  backgroundImage: '/asset/BG_rank.gif',
                  backgroundSize: '100% auto, 100% auto',
                  backgroundPosition: '0 0px, 0 0', // 背景2号从背景1号底部开始
                  backgroundRepeat: 'no-repeat, repeat-y',
                }}
              >
              <button 
                className="share-story-button" 
                onClick={handleShareStory}
                style={{
                  backgroundImage: `url(${process.env.PUBLIC_URL}/asset/BG_story.gif)`,
                  backgroundSize: '100% 100%',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                }}
              >
                <div className="team-container" >            
              {team}
              </div>
              </button> 
              
          </div>
          {haveFriend &&(<h2>Top {friends.length} friends</h2>)}
          {!haveFriend &&(<p>You don’t have friends yet</p>)}            
          
        {isLoading ? (
          <Loader /> // 加载时显示 Loader
        ) : (
           <ul className="friend-list">
        {friendList}
      </ul>
        )}
        </div>
        {showApplyButton && (
            <button className="apply-button" onClick={applySelection}>
              ✓ APPLY 
            </button>
          )}
        <div style={{ margin: '5px 0' }}></div>
        
        <div className="invite-container">
          <button className="invite-button" onClick={generateInviteLink}>
          INVITE FRIENDS
          </button>
          <button className="invite-button" onClick={copyInviteLink}>
          <FaCopy />
          </button>          
        </div>
      </div>
     
      </div>
      {process.env.NODE_ENV === 'development' && (
        <div className = 'debug-buttons'>
          <button className="debug-button" onClick={toggleStoryMaker}>
            Debug 
          </button>
          <button className="debug-button" onClick={RandomSkin}>
            Random Skin 
          </button>    
        </div>)}
      

      <div className='story-maker-container' style={{ transform: showStoryMaker ? "translate(0%, 0%)" : "translate(0%, 100%)" }}>        
          <div ref={contentRef} className='story-container'>
            <div className='story-background' style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}/asset/story_bg.png)`,
              backgroundSize: 'cover',
              backgroundPosition: 'top',
              backgroundRepeat: 'no-repeat',
              width: '100%',
              height: '100%',
              position: 'absolute',
              top: 0,
              left: 0,
              zIndex: -1
            }}>
            </div>
            <div className='story-content'>
            <div className='story-logo' style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/asset/LOGO.png)` }}></div>
            <div className='story-subtitle'>MY RECORD</div>
            <div className='story-user-cats'>
                <span className='number'>{userData.points}</span>
                <span className='text'>$CATZ</span>
            </div>
            <div className='story-user-max-combo'>
                <span className='number'>{userData.maxCombos}</span>
                <span className='text'>COMBOS</span>
            </div>

            {selectedFriends.length < 1 ? (
                <div className='story-text1'>
                  I'm all alone here... <br />Care to help me out?
                </div>
              ) : selectedFriends.length < 5 ? (
                <div className='story-text1'>
                  We're close! <br />Who's up for making it a full team?
                </div>
              ) : (
                <div className='story-text1'>
                  Easy for me,<br />
                  What about you?
                </div>
              )}
              <div className='story-user'>
              <div className='story-user-name'>{userData.first_name}{userData.last_name}</div>                
                <img src={debug ? randomUserIcon : userIcon} alt="user icon"/>
              </div>
              
              {debug ? makeUpFriendList.map((friend, i) => (
                <div key={i}>
                  <li className="story-friend-item" style={{ bottom: friend.bottomPosition, left: friend.leftPosition }}>
                    <img src={friend.skinURL} alt="Friend Icon" className="story-friend-avatar" />
                    <div className="story-friend-name">{friend.firstName} {friend.lastName}</div>
                  </li>
                </div>
              )) : selectedFriendList}
            </div>
          </div>
      </div> 
    </>
  );
});
export default Friend;
