import React from 'react';

function Controls({ toggleSound, isSoundOn }) {
  return (
    <div className="controls">
      <button onClick={toggleSound} className="sound-toggle-button">
        <img src={isSoundOn ? '/asset/UI_sound_on.png' : '/asset/UI_sound_off.png'} alt={isSoundOn ? 'Sound On' : 'Sound Off'} className="sound-toggle-icon" />
      </button>
    </div>
  );
}

export default Controls;
