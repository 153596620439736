import { useEffect, useRef , useCallback } from 'react';
import Matter from 'matter-js';
import useSound from 'use-sound';
import { characters, Properties,effectPresets,skin} from '../CharacterDatabase';


function useMatterJS(
  allFriends,
  selectedCharacter,
  selectedSkin,
  scene,
  combos,
  setCombos,
  setPendingGamePoints,
  isSoundOnRef,
  user,
  maxPendingPointsRef,
  userColorScheme
) {
  const [playCrowdCheering] = useSound(`${process.env.PUBLIC_URL}/asset/Sounds/CrowdCheering_1.wav`, { volume: 0.2 });
  const [playMaxSound] = useSound(`${process.env.PUBLIC_URL}/asset/Sounds/Combos_4.wav`, { volume: 0.5 });
  const [playHighSound] = useSound(`${process.env.PUBLIC_URL}/asset/Sounds/Combos_3.wav`, { volume: 0.5 });
  const [playMediumSound] = useSound(`${process.env.PUBLIC_URL}/asset/Sounds/Combos_2.wav`, { volume: 0.5 });
  const [playLowSound] = useSound(`${process.env.PUBLIC_URL}/asset/Sounds/Combos_1.wav`, { volume: 0.5 });
  const [playDefaultSound] = useSound(`${process.env.PUBLIC_URL}/asset/Sounds/Combos.wav`, { volume: 0.5 });
  const imageCache = useRef({});
  
  const debug = false;

  const debugFriends = [
    {
      iconUrl :'/asset/Skin/Icon_sw_robotcat.png',
      first_name: 'test',
      last_name: 'abcd',
      friendProfile: 'profile2'
    },
    {
      iconUrl: '/asset/Friends1.png',
      first_name: 'hehe',
      last_name: 'XD',
      friendProfile: 'profile3'
    }
  ];

  const drawArrow = useCallback((context, start, end) => {
    if (!start || !end) return; // 添加检查
    const headlen = 10; // length of head in pixels
    const angle = Math.atan2(end.y - start.y, end.x - start.x);
    const length = Math.sqrt((end.x - start.x) ** 2 + (end.y - start.y) ** 2);
    let color = '#00ff00'; // default color

    if (length > 100) {
      color = '#ffff00'; // yellow for medium length
    }
    if (length > 200) {
      color = '#ff0000'; // red for long length
    }

    context.beginPath();
    context.moveTo(start.x, start.y);
    context.lineTo(end.x, end.y);
    context.lineTo(end.x - headlen * Math.cos(angle - Math.PI / 6), end.y - headlen * Math.sin(angle - Math.PI / 6));
    context.moveTo(end.x, end.y);
    context.lineTo(end.x - headlen * Math.cos(angle + Math.PI / 6), end.y - headlen * Math.sin(angle + Math.PI / 6));
    context.strokeStyle = color;
    context.lineWidth = 2;
    context.stroke();
  },[]);

  const shootIndicator =useCallback((start, end , radius,engine) => {
    const indicator = Matter.Bodies.circle(start.x, start.y, radius, { // 與friend一樣大小
      restitution: 1,
      frictionAir: 0 ,
      render: {
       fillStyle: 'transparent',
      },
      collisionFilter: {
        category: 4,
        mask: 1  // 與所有物體保持碰撞
      },
      label: 'indicator'
    });
    const direction = {
      x: end.x - start.x,
      y: end.y - start.y,
    };
    const magnitude = Math.sqrt(direction.x ** 2 + direction.y ** 2);
    const normalizedDirection = {
      x: direction.x / magnitude,
      y: direction.y / magnitude,
    };
    const speed = 30; // 固定速度
    const velocity = {
      x: normalizedDirection.x * speed,
      y: normalizedDirection.y * speed,
    };
    Matter.Body.setVelocity(indicator, velocity);
    Matter.Composite.add(engine.world, indicator);

    setTimeout(() => {
      Matter.Composite.remove(engine.world, indicator);
    }, 250); // 2秒後自動消失

  },[]);

  const removeAllIndicators = useCallback((engine) => {
    const bodies = Matter.Composite.allBodies(engine.world);
    bodies.forEach(body => {
      if (body.label === 'indicator') {
        Matter.Composite.remove(engine.world, body);
      }
    });
  },[]);

  const  renderSprite = useCallback((context, body, img, xScale, yScale) => {
    const width = img.width * xScale * body.circleRadius / 50;
    const height = img.height * yScale * body.circleRadius / 50;
    context.save();
    context.translate(body.position.x, body.position.y);
    context.rotate(body.angle); // 根据body的角度旋转
    context.drawImage(img, -width / 2, -height / 2, width, height);
    context.restore();
  },[]);


  const createSparkEffect = useCallback((x, y ,engine,characterEffect) => {
    const effectPreset = effectPresets[characterEffect] || effectPresets.default;  
    let sparkCount = 3;
    const sparkBase = 2;
    const sparkRise=1;
    let sparkColor = { fillStyle: `${effectPreset.sparkColor}` };
    let sparkRadius = 1;
    const sparkRadiusBase = 1;
    const sparkRadiusRise = 0.2;
    
    if (combosRef.current > 40) {
      sparkCount = sparkBase + sparkRise*5;
      sparkColor = { fillStyle: processHsla(effectPreset.sparkColor,5,effectPreset.hueDir) };
      sparkRadius = sparkRadiusBase + sparkRadiusRise * 5;
    } else if (combosRef.current > 30) {
      sparkCount = sparkBase + sparkRise*4;
      sparkColor = { fillStyle: processHsla(effectPreset.sparkColor,4,effectPreset.hueDir) };
      sparkRadius = sparkRadiusBase + sparkRadiusRise * 4;
    } else if (combosRef.current > 20) {
      sparkCount = sparkBase + sparkRise*3;
      sparkColor = { fillStyle: processHsla(effectPreset.sparkColor,3,effectPreset.hueDir) };
      sparkRadius = sparkRadiusBase + sparkRadiusRise * 3;
    } else if (combosRef.current > 10) {
      sparkCount = sparkBase + sparkRise*2;
      sparkColor = { fillStyle: processHsla(effectPreset.sparkColor,2,effectPreset.hueDir) };
      sparkRadius = sparkRadiusBase + sparkRadiusRise * 2;
    } else if (combosRef.current > 5) {
      sparkCount = sparkBase + sparkRise*1;
      sparkColor = { fillStyle: processHsla(effectPreset.sparkColor,1,effectPreset.hueDir) };
      sparkRadius = sparkRadiusBase + sparkRadiusRise;
    }
  
    for (let i = 0; i < sparkCount; i++) {
      // 为每个火花随机调整颜色
      const randomColor = randomizeHsla(sparkColor.fillStyle);
      const circleRadius = sparkRadius*3 * Math.random() + sparkRadius*1;
      const smallSpark = Matter.Bodies.circle(x, y, circleRadius,{
        restitution: 0.8,
        frictionAir: 0.02,
        render: { fillStyle: randomColor },
        collisionFilter: {
          category: 4,
          mask: 1
        },
        label: 'spark'
      });
      const sparkAngle = Math.random() * 2 * Math.PI;
      const sparkSpeed = Math.random() * 5 + 2;
      Matter.Body.setVelocity(smallSpark, {
        x: Math.cos(sparkAngle) * sparkSpeed,
        y: Math.sin(sparkAngle) * sparkSpeed
      });
  
      Matter.Composite.add(engine.world, smallSpark);
  
      setTimeout(() => {
        Matter.Composite.remove(engine.world, smallSpark);
      }, 500); // 火花0.5秒后消失

      if(effectPreset.addsparkShape)
      {
        const shapeUrl = effectPreset.sparkShape;
        const sparkScale = 0.7* Math.random() + 0.5
        // 创建星星形状
        const shapeSpark = Matter.Bodies.circle(x, y, 1, {
          restitution: 1,
          frictionAir: 0.02,
          density: 50,
          //render: { fillStyle: randomColor },
          render: {
          sprite: {
            texture: shapeUrl,
            xScale: sparkScale,
            yScale: sparkScale,              
            },
          },
          collisionFilter: {
          category: 4,
          mask: 1
          },
          label: 'spark'
        });
        const shapeAngle = Math.random() * 2 * Math.PI;
        const shapeSpeed = Math.random() * 5 + 2;
        Matter.Body.setVelocity(shapeSpark, {
         x: Math.cos(shapeAngle) * shapeSpeed,
         y: Math.sin(shapeAngle) * shapeSpeed
        });
  
        Matter.Composite.add(engine.world, shapeSpark);
  
        setTimeout(() => {
          Matter.Composite.remove(engine.world, shapeSpark);
        }, 1000); // 火花0.5秒后消失
      } 
    }
  },[]);

  function processHsla(hslaColor,step,hueDir) {
    const hslParts = hslaColor.match(/hsla\((\d+),\s*(\d+)%,\s*(\d+)%,\s*(\d?.?\d*)\)/);
    
    let hue = parseInt(hslParts[1]);
    let saturation = parseInt(hslParts[2]);
    let lightness = parseInt(hslParts[3]);
    let alpha = parseFloat(hslParts[4]);
  
    // 在当前色相上随机波动正负20度
    hue = hue + Math.floor(5*step*hueDir + 360) % 360;
    saturation = Math.min(100, saturation + 10*step);
    alpha = Math.min(1, alpha + 0.1*step);
  
    return `hsla(${hue}, ${saturation}%, ${lightness}%, ${alpha})`;
  }
  
  // 随机调整 hsla 值
  function randomizeHsla(hslaColor) {
    const hslParts = hslaColor.match(/hsla\((\d+),\s*(\d+)%,\s*(\d+)%,\s*(\d?.?\d*)\)/);
    
    let hue = parseInt(hslParts[1]);
    let saturation = parseInt(hslParts[2]);
    let lightness = parseInt(hslParts[3]);
    let alpha = parseFloat(hslParts[4]);
  
    // 在当前色相上随机波动正负20度
    hue = (hue + Math.floor(Math.random() * 40 - 20) + 360) % 360;
    // 随机调整亮度
    lightness = Math.min(100, Math.max(30, lightness + Math.floor(Math.random() * 20 - 10)));
  
    return `hsla(${hue}, ${saturation}%, ${lightness}%, ${alpha})`;
  }

  const addTracerEffect = useCallback((body, characterEffect,engine) => {
    const lastPositions = [];
    const effectPreset = effectPresets[characterEffect] || effectPresets.default;  
    const tracerInterval = setInterval(() => {
      const speed = Math.sqrt(body.velocity.x ** 2 + body.velocity.y ** 2);
      if (speed < 1) {
        clearInterval(tracerInterval);
        return;
      }
      lastPositions.push({ x: body.position.x, y: body.position.y });
  
      if (lastPositions.length >= 2) {
        const [start, end] = lastPositions;
        const steps = 5;
        for (let i = 1; i <= steps; i++) {
          const interpolatedX = start.x + (end.x - start.x) * (i / steps);
          const interpolatedY = start.y + (end.y - start.y) * (i / steps);
          let tracer = Matter.Bodies.circle(interpolatedX, interpolatedY, effectPreset.initialRadius, {
            isStatic: true,
            render: {
              fillStyle: effectPreset.traceColorGradient(1),
              opacity: 0.5
            },
            collisionFilter: {
              category: 4,
              mask: 1
            },
            label: 'tracer'
          });
          Matter.Composite.add(engine.world, tracer);
          const shrinkInterval = setInterval(() => {
            if (tracer.circleRadius > 1) {
              Matter.Body.scale(tracer, effectPreset.shrinkRate, effectPreset.shrinkRate);
  
              const ratio = tracer.circleRadius / effectPreset.initialRadius;
              tracer.render.fillStyle = effectPreset.traceColorGradient(ratio);
            } else {
              Matter.Composite.remove(engine.world, tracer);
              clearInterval(shrinkInterval);
            }
          }, 10);
        }
        lastPositions.shift();
      }     
    }, 10);

    const tracerShapeInterval = setInterval(() => {
      const speed = Math.sqrt(body.velocity.x ** 2 + body.velocity.y ** 2);
      if (speed < 1) {
        clearInterval(tracerShapeInterval);
        return;
      }
      if(effectPreset.addShape)
        {
          const shapeUrl = effectPreset.traceShape
          const traceShapescale = 0.5;
          // 创建星星形状
          var traceShape = Matter.Bodies.circle(body.position.x, body.position.y, 10, {
            restitution: 0.8,
            frictionAir: 0.02,
            //render: { fillStyle: '#FFFFFF' },
            render: {
              sprite: {
                texture: shapeUrl,
                xScale: traceShapescale,
                yScale: traceShapescale,              
              },
            },
            collisionFilter: {
              category: 4,
              mask: 1
            },
            label: 'trace'
          });
          const angle = Math.random() * 2 * Math.PI;
          const speed = Math.random() * 1 + 0.5;
          Matter.Body.setAngle(traceShape,angle,false)
          Matter.Body.setVelocity(traceShape, {
            x: Math.cos(angle) * speed,
            y: Math.sin(angle) * speed
          });
          if(effectPreset.traceShapeRotate)
          {
          Matter.Body.setAngularSpeed(traceShape, 0.1);
          }
          
          Matter.Composite.add(engine.world, traceShape);
          setTimeout(() => {
            Matter.Composite.remove(engine.world, traceShape);
          }, 300); // 火花0.5秒后消失
        }
    },100);
  
    const stopTracerEffect = () => {
      clearInterval(tracerInterval);
      clearInterval(tracerShapeInterval);
    };

    const updateHandler = () => {
      const speed = Math.sqrt(body.velocity.x ** 2 + body.velocity.y ** 2);
      if (speed < 0.1) {
        Matter.Events.off(engine, 'beforeUpdate', updateHandler);
        stopTracerEffect();
      }
    };
  
    Matter.Events.on(engine, 'beforeUpdate', updateHandler);
  },[]);

  useEffect(() => {
    maxPendingPointsRef.current = 500;
  }, []);

  const combosRef = useRef(combos);

  useEffect(() => {
    combosRef.current = combos;
  }, [combos]);

  useEffect(() => {
    const container = scene.current;
    const scaledWidth = container.clientWidth;
    const scaledHeight = container.clientHeight;
    // Matter.js setup
    const { Engine, Render, Runner, Composite, MouseConstraint, Mouse, Body, Bodies, Bounds, Vertices, Events, Common } = Matter;

    const engine = Engine.create(),
      world = engine.world;
    engine.world.gravity.y = 0;
    engine.constraintIterations = 1; // 減少迭代次數
    engine.positionIterations = 4; // 減少迭代次數
    engine.velocityIterations = 3; // 減少迭代次數

    const render = Render.create({
      element: scene.current,
      engine: engine,
      options: {
        wireframes: false,
        width: scaledWidth,
        height: scaledHeight,
        showAngleIndicator: false,
        background: 'rgba(255, 255, 255, 0)',
        hasBounds: false,
        pixelRatio: window.devicePixelRatio,
      }
    });

    Render.run(render);

    const runner = Runner.create({
      isFixed: true,
      delta: 1000 / 60,
      maxFrameTime: 1000 / 60
    });

    Runner.run(runner, engine);

    const wall_thickness = 1000;
    const wall_offset = wall_thickness/2;
    const wall_setting = 
    { isStatic: true,
      restitution: 1,
      friction :5,
      density: 50,
      render:{ fillStyle: '#ff0000' },
      collisionFilter: { category: 1 } 
    }
    const wall_top = Bodies.rectangle(0, scaledHeight/2 + wall_offset, scaledWidth*5, wall_thickness, wall_setting)
    const wall_bottom = Bodies.rectangle(0, -scaledHeight/2 - wall_offset, scaledWidth*5, wall_thickness, wall_setting)
    const wall_left = Bodies.rectangle(scaledWidth/2 + wall_offset, 0, wall_thickness, scaledHeight*5, wall_setting)
    const wall_right = Bodies.rectangle(-scaledWidth/2 - wall_offset, 0, wall_thickness, scaledHeight*5, wall_setting)

    const targetScale = 0.22;
    const target_texture = userColorScheme === 'light' ? '/asset/UI_sun.png' : '/asset/UI_moon.png';
    const target_radius = userColorScheme === 'light' ? scaledHeight *0.21 :scaledHeight *0.14;
    const target_restitution = userColorScheme === 'light' ? 0 : 1;
    const targetY = -scaledHeight/2 + scaledHeight*0.35;

    const target_body = Bodies.circle(0, targetY, target_radius, { 
      isStatic: true,
      label: 'target',
      restitution: target_restitution,
      density:5,
      render: {
        visible :true,
        sprite: {
          texture: target_texture,
          xScale: targetScale,
          yScale: targetScale
        },
      },
      collisionFilter: { category: 1 }
    });

    Composite.add(engine.world, [ wall_top,wall_bottom,wall_left,wall_right,target_body]); 

    const friendScale = 0.1;
   

    let friendlist = allFriends;
    if(debug) friendlist = debugFriends;

    // 使用 debugFriends 代替 allFriends
    for (let i = 0; i < friendlist.length; i++) {
      const friend = friendlist[i];
      let friendCharacter = 1;
      const friendSkin = friend.skin;
      let friendName = '';
      let friendIconUrl = '';
      let friendProfile = 'profile1';
      let effectPreset = 'default';


      if(!debug)
      {
        friendName = friend.first_name + ' ' + friend.last_name; // 確保有值
        friendIconUrl = friend.dayNight === 'day' ? skin.find(skin => skin.id === friendSkin).dayUrl : skin.find(skin => skin.id === friendSkin).nightUrl;
         friendProfile = characters.find(character => character.id === friendCharacter).profile;
         effectPreset = skin.find(skin => skin.id === friendSkin).effectPreset;
         friendCharacter = friend.selectedCharacter;
      }else{
        friendName = debugFriends[i].first_name+' '+debugFriends[i].last_name;
        friendIconUrl = debugFriends[i].iconUrl
        friendProfile = debugFriends[i].friendProfile;
      }
      const { restitution, density, radius, frictionAir, mass } = Properties[friendProfile];
      const x = Math.random() * scaledWidth * 2 - scaledWidth;
      const y = Math.random() * scaledHeight * 2 - scaledHeight;

      const scaledFriendRadius = radius * scaledWidth * 0.003;
      const modifiedrestitution = userColorScheme === 'light' ? restitution - 0.05 : restitution;
      const modifiedFrictionAir = userColorScheme === 'light' ? frictionAir * 1.3 : frictionAir;

      const textColor = userColorScheme === 'light' ? '#000' : '#FFF';

      const friendBody = Bodies.circle(
        x, // 随机x位置
        y, // 随机y位置
        scaledFriendRadius,
        {
          restitution: modifiedrestitution,
          density: density,
          frictionAir: modifiedFrictionAir,
          mass: mass,
          label: 'shooter_' + friendProfile,
          effectPreset: effectPreset,
          render: {
            sprite: {
              texture: friendIconUrl,
              xScale: friendScale,
              yScale: friendScale,
            },
            text: {
              content: friendName,
              color: textColor, // 文字颜色
              size: 12, // 文字大小
              yOffset: 20 // 向下移动20像素
            }
          },
          collisionFilter: { category: 2, mask: 1 | 2 }
        }
      );

      // 清理旧的 sprite
      friendBody.render.sprite = null;

      // 设置新的 sprite
      friendBody.render.sprite = {
        texture: friendIconUrl,
        xScale: friendScale,
        yScale: friendScale
      };

      Composite.add(engine.world, friendBody);
    }

    // 确保allFriends生成完成后才允许互动
    let interactionAllowed = false;
    setTimeout(() => {
      interactionAllowed = true;
    }, 1000); // 假设1秒后生成完成


    //Debug
    let userCount = 1

    for (let i = 0; i <userCount; i++) 
    {
      const userCharacter = selectedCharacter;
      
      let userName = 'player'
      if(user)
      {
        userName = user.username;
      }
      let userSkin = selectedSkin;
      let userIcon = userColorScheme === 'light' ? skin.find(skin => skin.id === userSkin).dayUrl : skin.find(skin => skin.id === userSkin).nightUrl;
      let userProfile = characters.find(character => character.id === userCharacter).profile;
      let effectPreset = skin.find(skin => skin.id === userSkin).effectPreset;    

      const textColor = userColorScheme === 'light'? '#000':'#FFF';

      const { restitution, density, radius, frictionAir , mass } = Properties[userProfile];
      const modifiedrestitution = userColorScheme === 'light' ? restitution -0.05 :restitution;
      const modifiedFrictionAir = userColorScheme === 'light' ? frictionAir *1.5 :frictionAir;
      const scaledUserRadius = radius *scaledWidth *0.003;
      const userBody = Bodies.circle(
        0, // 随机x位置
        140, // 随机y位置
        scaledUserRadius,
        { 
          restitution: modifiedrestitution,
          density: density,
          frictionAir: modifiedFrictionAir ,
          mass: mass,
          effectPreset: effectPreset,
          Inertia : 50,
          label: 'shooter_'+userProfile,
          render: {
            sprite: {
              texture: userIcon,
              xScale: friendScale,
              yScale: friendScale,
            },
            text: {
              content: userName,
              color: textColor, // 文字顏色
              size: 12, // 文字大小
              yOffset: 20 // 向下移动20像素
            }
          },
          collisionFilter: { category: 2, mask: 1|2 }
        }
      );
      // 清理舊的 sprite
      userBody.render.sprite = null;
      // 設置新的 sprite
      userBody.render.sprite = {
        texture: userIcon,
        xScale: friendScale,
        yScale: friendScale
      };
      
      Composite.add(engine.world, userBody);
    }

    const mouse = Mouse.create(render.canvas),
      mouseConstraint = MouseConstraint.create(engine, {
        mouse: mouse,
        constraint: {
          stiffness: 0,
          render: {
            visible: false
          }
        }
      });

    let selectedObject = null;
    let initialMousePosition = { x: 0, y: 0 };

    const arrow = {
      start: null,
      end: null,
      shootInterval: null,
    };

    Events.on(mouseConstraint, 'mousedown', function (event) {
      if (!interactionAllowed) return;
      const mousePosition = event.mouse.position;
      const bodies = Composite.allBodies(engine.world);

      for (let i = 0; i < bodies.length; i++) {
        if (bodies[i].label.includes('shooter') && Bounds.contains(bodies[i].bounds, mousePosition) && Vertices.contains(bodies[i].vertices, mousePosition)) {
          selectedObject = bodies[i];
          Body.setVelocity(selectedObject, { x: 0, y: 0 });
          Body.setAngularSpeed(selectedObject, 0);
          Body.setStatic(selectedObject, true);
          initialMousePosition = { x: mousePosition.x, y: mousePosition.y };
          arrow.start = { x: selectedObject.position.x, y: selectedObject.position.y };
          break;
        }
      }
    });

    Events.on(mouseConstraint, 'mousemove', function (event) {
      if (!interactionAllowed) return;
      if (selectedObject) {
        removeAllIndicators(engine);
        const mousePosition = event.mouse.position;
        const deltaX = mousePosition.x - arrow.start.x;
        const deltaY = mousePosition.y - arrow.start.y;
        arrow.end = { x: arrow.start.x - deltaX, y: arrow.start.y - deltaY };

        if (!arrow.shootInterval) {
          arrow.shootInterval = setInterval(() => {
            if (arrow.start && arrow.end) {
              shootIndicator(arrow.start, arrow.end, selectedObject.circleRadius, engine);
            }
          }, 50); // 增加間隔時間
        }
      }
    });

    const velocityFactor = 0.5;
    Events.on(mouseConstraint, 'mouseup', function (event) {
      if (!interactionAllowed) return;
      if (selectedObject) {
        removeAllIndicators(engine);
        const mousePosition = event.mouse.position;
        const deltaX = mousePosition.x - initialMousePosition.x;
        const deltaY = mousePosition.y - initialMousePosition.y;
        const Velocity = { x: -deltaX * velocityFactor, y: -deltaY * velocityFactor };
        const effectPreset = selectedObject.effectPreset;
        Body.setVelocity(selectedObject, Velocity);
        Body.setStatic(selectedObject, false);
        Body.setAngularVelocity(selectedObject, 1);
        selectedObject.collisionFilter.category = 2;
        addTracerEffect(selectedObject, effectPreset, engine);

        selectedObject = null;
        setCombos(0);
        arrow.start = null;
        arrow.end = null;

        clearInterval(arrow.shootInterval);
        arrow.shootInterval = null;
      }
    });

    Composite.add(world, mouseConstraint);

    render.mouse = mouse;

    Render.lookAt(render, {
      min: { x: -scaledWidth / 2, y: -scaledHeight / 2 },
      max: { x: scaledWidth / 2, y: scaledHeight / 2 }
    });

    // 碰撞事件监听
    Events.on(engine, 'collisionStart', function(event) {
      const pairs = event.pairs;
      pairs.forEach(pair => {
        if ((pair.bodyA.label === 'target' && pair.bodyB.label.includes('shooter')) || 
            (pair.bodyB.label === 'target' && pair.bodyA.label.includes('shooter'))) 
        {
          setCombos(prevCombos => {
            const newCombos = prevCombos + 1;
            combosRef.current = newCombos;
            return newCombos;
          });

          setPendingGamePoints(prevPendingGamePoints => {
            if (prevPendingGamePoints + 1 > maxPendingPointsRef.current) {
              return prevPendingGamePoints;
            }
            return prevPendingGamePoints + 1;
          });

          
          if (isSoundOnRef.current) {
            //當combo達到30時播放歡呼聲
            if (combosRef.current === 30) {
              playCrowdCheering();
            }
            if (combosRef.current > 20) {
              playMaxSound();
            } else if (combosRef.current > 10) {
              playHighSound();
            } else if (combosRef.current > 5) {
              playMediumSound();
            } else if (combosRef.current > 3) {
              playLowSound();
            } else {
              playDefaultSound();
            }
          }

          // 震動反饋
          if (window.Telegram && window.Telegram.WebApp) {
            //window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
          }

          // 反應動畫
          const targetBody = pair.bodyA.label === 'target' ? pair.bodyA : pair.bodyB;
          const characterBody = pair.bodyA.label === 'target' ? pair.bodyB : pair.bodyA;
          const originalScale = 1;
          let newScale = 1.2;
          // 清除原有的 sprite
          targetBody.render.sprite = null;

          // 放大
          Matter.Body.scale(targetBody, originalScale, originalScale);

          // 設置新的 sprite
          targetBody.render.sprite = {
            texture: target_texture,
            xScale: targetScale * newScale,
            yScale: targetScale * newScale
          };

          setTimeout(() => {
            // 清除原有的 sprite
            targetBody.render.sprite = null;

            // 恢復原狀
            Matter.Body.scale(targetBody, originalScale, originalScale);

            // 設置新的 sprite
            targetBody.render.sprite = {
              texture: target_texture,
              xScale: targetScale,
              yScale: targetScale
            };
          }, 50);

          // 添加火花特效
          const collisionPoint = pair.collision.supports[0];
          createSparkEffect(collisionPoint.x, collisionPoint.y,engine,characterBody.effectPreset);
        }
      });
    });
    
    // 自定义渲染函数
    Matter.Render.bodies = (function(original) {
        return function(render, bodies, context) {
          original(render, bodies, context);
          const c = context;
          c.font = '12px Arial';
          c.fillStyle = '#FFFFFF';
          c.textAlign = 'center';
          c.textBaseline = 'middle';
          bodies.forEach(body => {
            if (body.render.sprite && body.render.sprite.texture && (body.label === 'target' || body.label.includes('shooter'))) {
              const { texture, xScale, yScale } = body.render.sprite;
              if (!imageCache[texture]) {
                const img = new Image();
                img.src = texture;
                img.onload = () => {
                  imageCache[texture] = img;
                  renderSprite(c, body, img, xScale, yScale,engine);
                };
              } else {
                const img = imageCache[texture];
                renderSprite(c, body, img, xScale, yScale,engine);
              }
            }
            if (body.render.text) {
              const { content, color, size } = body.render.text;
              c.fillStyle = color || '#FFFFFF';
              c.font = `${size || 12}px Arial`;
              c.fillText(content, body.position.x, body.position.y + 20); // 向下移动20像素
            }
            if (body.label === 'indicator') {
              const indicatorScale = 0.1; // 使indicator的視覺大小變小
              c.beginPath();
              c.arc(body.position.x, body.position.y, body.circleRadius * indicatorScale, 0, 2 * Math.PI);
              c.fillStyle = '#FFFFFF';
              c.fill();
            }
          });
          if (arrow.start && arrow.end) { // 添加检查
            drawArrow(context, arrow.start, arrow.end,engine);
          }
        };
      })(Matter.Render.bodies);     
    

      return () => {
        Events.off(mouseConstraint, 'mousedown');
        Events.off(mouseConstraint, 'mousemove');
        Events.off(mouseConstraint, 'mouseup');
        Events.off(engine, 'collisionStart');
        Render.stop(render);
        Runner.stop(runner);
        Engine.clear(engine);
        render.canvas.remove();
        render.canvas = null;
        render.context = null;
        render.textures = {};
      };
    
  }, [
    allFriends,
    selectedCharacter,
    selectedSkin,
    user,
    userColorScheme,
    setCombos,
    setPendingGamePoints,
    combosRef,
    maxPendingPointsRef,
    scene
  ]);

  return null;
}

export default useMatterJS;