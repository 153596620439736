import React from 'react';
import '../Character.css';

const StatBar = ({ value, max }) => {
  const totalCells = 5;
  const filledCells = Math.round((value / max) * totalCells);

  // 使用模板字符串直接生成 barClass
  const barClass = `filled-${filledCells}`;

  return (
    <div className={`stat-bar ${barClass}`}>
      {[...Array(totalCells)].map((_, index) => (
        <div
          key={index}
          className={`stat-bar-cell ${index < filledCells ? 'filled' : ''}`}
        >
        </div>
      ))}
    </div>
  );
};

export default StatBar;

