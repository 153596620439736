import React, { memo, useEffect } from 'react';
import './Background.css';
import { DotLottiePlayer } from '@dotlottie/react-player';


const Background = memo((dayNightChange) => {
  const isLight = window.Telegram.WebApp.colorScheme === 'light'; 

  useEffect(() => {
    console.log(dayNightChange.dayNightChange);
    let timeoutId;
    const nightElement = document.querySelector('.background-night');
    const dayElement = document.querySelector('.background-day');      
    if (dayNightChange.dayNightChange) {
      
      if (isLight) {
        nightElement.style.opacity = '1';        
        dayElement.style.opacity = '0';

        nightElement.style.zIndex = '1';
        dayElement.style.zIndex = '0';

        timeoutId = setTimeout(() => {
          nightElement.style.opacity = '0';
          dayElement.style.opacity = '1';
        }, 1000);
      }
      else
      {
        nightElement.style.opacity = '0';
        dayElement.style.opacity = '1';

        timeoutId = setTimeout(() => {
          nightElement.style.opacity = '1';
          dayElement.style.opacity = '0';
        }, 1000);
      }
    }
    else
    {
      if (isLight) {
        nightElement.style.opacity = '0';        
        dayElement.style.opacity = '1';
        nightElement.style.zIndex = '0';
        dayElement.style.zIndex = '1';
      } else{
        nightElement.style.opacity = '1';        
        dayElement.style.opacity = '0';
      }

    }
    return () => clearTimeout(timeoutId);
  }, [dayNightChange, isLight]);

  return (
    <div className='background'>
      <div className='background-day'></div>     
      <div className='background-night'></div>
       {isLight?(
        <img
        alt='sky'
        className='DayBG'
        src={`${process.env.PUBLIC_URL}/asset/Day_BG.webp`} >
        </img>
        ):(
        <DotLottiePlayer
          src={`${process.env.PUBLIC_URL}/asset/lottie/Night_BG.lottie`}
          autoplay
          loop
          className='DotLottie'
        />)}
      
    </div>
  );
});

export default Background;