import React, { useEffect, useState,useMemo, memo } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
  useLocation,
  Navigate,
} from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { db } from './firebase';

import './App.css';
import './Molecules/Footer.css';
import { SpeedInsights } from '@vercel/speed-insights/react';
import { TonConnectUIProvider } from '@tonconnect/ui-react';
import FetchLeaderboards from './components/FetchLeaderboards';

import WelcomePage from './WelcomePage';
import Starter from './Starter';
import Friend from './Friend';
import Task from './Task';
import Main from './Main';
import Leaderboard from './Leaderboard';
import Character from './Character';
import Footer from './Molecules/Footer';
import Background from './Background';

const manifestUrl = 'https://catz.games/tonconnect-manifest.json';

const App = memo(() => {
  const [userColorScheme, setUserColorScheme] = useState('light');

  useEffect(() => {
    // Dynamically load Eruda script in development mode
    if (process.env.NODE_ENV === 'development') {
      const script = document.createElement('script');
      script.src = 'https://cdn.jsdelivr.net/npm/eruda';
      script.onload = () => {
        if (window.eruda) {
          window.eruda.init();
        }
      };
      document.body.appendChild(script);
    }

    if (window.Telegram?.WebApp) {
      const tg = window.Telegram.WebApp;
      tg.ready();
      tg.expand();
      tg.disableVerticalSwipes();

      const colorScheme = tg.colorScheme;
      const mode = colorScheme === 'dark' ? 'dark' : 'light';
      setUserColorScheme(mode);
      tg.setHeaderColor('#FFDD00');

      document.documentElement.style.overflow = 'hidden';
      document.body.style.overflow = 'hidden';

      document.body.classList.toggle('night-mode', mode === 'dark');
      document.body.classList.toggle('day-mode', mode !== 'dark');
    }
  }, []);

  return (
    <Router>
      <TonConnectUIProvider
        manifestUrl={manifestUrl}
        actionsConfiguration={{
          twaReturnUrl: 'https://t.me/catz_community',
        }}
      >
        <AppContent userColorScheme={userColorScheme} />
      </TonConnectUIProvider>
      <SpeedInsights />
    </Router>
  );
});

const AppContent = (({ userColorScheme }) => {
  const [user, setUser] = useState(null);
  const [selectedCharacter, setSelectedCharacter] = useState(1);
  const [dayNight, setDayNight] = useState('day');
  const [inviterId, setInviterId] = useState(-1);

  const [showStarter, setShowStarter] = useState(false);
  const [showWelcomePage, setShowWelcomePage] = useState(false);

  const [consecutiveDays, setConsecutiveDays] = useState(1);
  const [showDayNightChange, setShowDayNightChange] = useState(false);
  const [showConsecutiveDays, setShowConsecutiveDays] = useState(false);

  const [pointsLeaderboard, setPointsLeaderboard] = useState([]);
  const [maxCombosLeaderboard, setMaxCombosLeaderboard] = useState([]);
  const [lastUpdate, setLastUpdate] = useState(0);

  const [initializationComplete, setInitializationComplete] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  useInitializeUser({
    setUser,
    setSelectedCharacter,
    setDayNight,
    setInviterId,
    setShowStarter,
    setShowWelcomePage,
    setConsecutiveDays,
    setShowDayNightChange,
    setShowConsecutiveDays,
    setInitializationComplete,
  });

  useEffect(() => {
    if (initializationComplete) {
      if (showWelcomePage) {
        navigate('/welcome');
        setShowWelcomePage(false);
      } else if (showStarter) {
        navigate('/starter');
        setShowStarter(false);
      } else if (location.pathname === '/') {
        navigate('/main');
      }
    }
  }, [initializationComplete, showWelcomePage, showStarter, navigate, location.pathname]);

  useEffect(() => {
    const fetchDataIfNeeded = async () => {
      const now = Date.now();
      const fifteenMinutes = 15 * 60 * 1000;

      if (location.pathname === '/leaderboard' || location.pathname === '/character') {
        if (now - lastUpdate > fifteenMinutes) {
          const { pointsLeaderboard, maxCombosLeaderboard, lastUpdate } = await FetchLeaderboards();
          setPointsLeaderboard(pointsLeaderboard || []);
          setMaxCombosLeaderboard(maxCombosLeaderboard || []);
          setLastUpdate(lastUpdate);
        }
      }
    };

    fetchDataIfNeeded();
  }, [location.pathname, lastUpdate,pointsLeaderboard]);

  const shouldShowFooter =
    location.pathname !== '/welcome' && location.pathname !== '/starter' && location.pathname !== '/';

  return (
    <div className="AppContent">
      <Background dayNightChange={showDayNightChange}/>
      <Routes>
        <Route path="/" />
        <Route
          path="/main"
          element={
            <Main
              user={user}
              selectedCharacter={selectedCharacter}
              userColorScheme={userColorScheme}
            />
          }
        />
        <Route path="/task" element={<Task user={user} />} />
        <Route path="/friend" element={<Friend user={user} />} />
        <Route path="/leaderboard" element={<Leaderboard pointsLeaderboard={pointsLeaderboard} maxCombosLeaderboard={maxCombosLeaderboard}/>} />
        <Route path="/character" element={<Character pointsLeaderboard={pointsLeaderboard} maxCombosLeaderboard={maxCombosLeaderboard}/>} />
        <Route path="/welcome" element={<WelcomePage inviterID={inviterId} />} />
        <Route
          path="/starter"
          element={
            <Starter
              consecutiveDays={consecutiveDays}
              dayNight={dayNight}
              showDayNightChange={showDayNightChange}
              showConsecutiveDays={showConsecutiveDays}
            />
          }
        />
      </Routes>
      {shouldShowFooter && <Footer />}
    </div>
  );
});

function useInitializeUser({
  setUser,
  setSelectedCharacter,
  setDayNight,
  setInviterId,
  setShowStarter,
  setShowWelcomePage,
  setConsecutiveDays,
  setShowDayNightChange,
  setShowConsecutiveDays,
  setInitializationComplete,
}) {
  useEffect(() => {
    const initializeUser = async () => {
      const tg = window.Telegram?.WebApp;

      if (!tg?.initDataUnsafe?.user) {
        console.error('无法获取 Telegram WebApp 用户数据。');
        return;
      }

      const currentUser = tg.initDataUnsafe.user;
      const userId = currentUser.id.toString();

      const userData = {
        id: userId,
        first_name: currentUser.first_name || '',
        last_name: currentUser.last_name || '',
        username: currentUser.username || '',
      };

      setUser(userData);

      const colorScheme = tg.colorScheme;
      const currentDayNight = colorScheme === 'dark' ? 'night' : 'day';
      setDayNight(currentDayNight);

      const userRef = db.collection('users').doc(userId);

      try {
        const doc = await userRef.get();
        const now = firebase.firestore.Timestamp.now();
        const nowDate = now.toDate();

        const isFirstLogin = !doc.exists;
        let consecutiveDays = 1;
        let showStarter = false;
        let showWelcomePage = false;

        if (isFirstLogin) {
          await handleFirstLogin(userRef, userData, currentDayNight);
          showWelcomePage = true;
          setConsecutiveDays(1);
        } else {
          const userDocData = doc.data();
          const lastLoginDate = userDocData.lastLogin.toDate();

          const isSameLoginDay = isSameDay(nowDate, lastLoginDate);
          const isConsecutiveLogin = isSameDay(nowDate, addDays(lastLoginDate, 1));

          await handleSubsequentLogin(userRef, userDocData, currentDayNight, isConsecutiveLogin, isSameLoginDay);

          // 更新 selectedCharacter
          setSelectedCharacter(userDocData.selectedCharacter || 1);

          // 检查 dayNight 是否改变
          const dayNightChanged = userDocData.dayNight !== currentDayNight;
          setShowDayNightChange(dayNightChanged);

          // 检查 consecutiveDays 是否增加
          if (!isSameLoginDay) {
            // 只有在新的一天登录时才需要更新 consecutiveDays
            if (isConsecutiveLogin) {
              consecutiveDays = userDocData.consecutiveDays + 1;
            } else {
              consecutiveDays = 1;
            }
            setConsecutiveDays(consecutiveDays);

            // 当 consecutiveDays 增加时，显示 Starter 页面
            setShowConsecutiveDays(true);
          } else {
            consecutiveDays = userDocData.consecutiveDays;
            setConsecutiveDays(consecutiveDays);
            setShowConsecutiveDays(false);
          }

          // 如果 dayNight 改变或新的一天登录，显示 Starter 页面
          if (dayNightChanged || !isSameLoginDay) {
            showStarter = true;
          }
        }

        setShowStarter(showStarter);
        setShowWelcomePage(showWelcomePage);

        // 处理邀请人
        const startParam = tg.initDataUnsafe.start_param;
        if (startParam && isFirstLogin) {
          const inviterIdParam = startParam.split('_')[1];
          setInviterId(inviterIdParam);
          if (inviterIdParam) {
            await updateInviter(inviterIdParam, userId);
          }
        }
      } catch (error) {
        console.error('初始化用户时出错：', error);
      }
      setInitializationComplete(true);
    };

    initializeUser();
  }, [
    setUser,
    setSelectedCharacter,
    setDayNight,
    setInviterId,
    setShowStarter,
    setShowWelcomePage,
    setConsecutiveDays,
    setShowDayNightChange,
    setShowConsecutiveDays,
    setInitializationComplete,
  ]);
}
// Helper Functions

async function handleFirstLogin(userRef, userData, dayNight) {
  const initialData = {
    ...userData,
    consecutiveDays: 1,
    friends: [],
    selected_friends: [],
    taskPoints: 1500,
    loginPoints: 500,
    gamePoints: 0,
    maxCombos: 0,
    todayGamePoints: 0,
    friendPoints: 0,
    points: 2000, // taskPoints + loginPoints
    availableCharacters: [1],
    availableSkins: [101, 201, 301],
    selectedCharacter: 1,
    skin: 101,
    completedTasks: dayNight === 'night' ? ['blackcat'] : ['whitecat'],
    dayNight,
    lastLogin: firebase.firestore.Timestamp.now(),
  };

  await userRef.set(initialData);
}
async function handleSubsequentLogin(userRef, userData, dayNight, isConsecutiveLogin, isSameLoginDay) {
  const now = firebase.firestore.Timestamp.now();
  let consecutiveDays = userData.consecutiveDays || 1;
  let loginPoints = 0;

  if (!isSameLoginDay) {
    // 用户在新的一天登录
    if (isConsecutiveLogin) {
      // 连续登录，增加 consecutiveDays
      consecutiveDays += 1;
    } else {
      // 未连续登录，重置 consecutiveDays
      consecutiveDays = 1;
    }

    // 计算 loginPoints
    loginPoints = 500 + (consecutiveDays - 1) * 50;

    // 更新数据库中的 consecutiveDays 和 loginPoints
    await userRef.update({
      consecutiveDays,
      loginPoints: firebase.firestore.FieldValue.increment(loginPoints),
      lastLogin: now,
      todayGamePoints: 0,
    });
  } else {
    // 用户已经在今天登录过，不需要更新 consecutiveDays 和 loginPoints，只需更新 dayNight（如果有变化）
    if (userData.dayNight !== dayNight) {
      await userRef.update({
        dayNight,
      });
    }
  }
}

async function updateInviter(inviterId, userId) {
  try {
    const inviterRef = db.collection('users').doc(inviterId);
    await inviterRef.update({
      friends: firebase.firestore.FieldValue.arrayUnion(userId),
      friendPoints:firebase.firestore.FieldValue.increment(1000),
      points:firebase.firestore.FieldValue.increment(1000)
    });
    console.log(`Inviter ${inviterId} updated successfully with user ${userId}.`);

    const userRef = db.collection('users').doc(userId);
    await userRef.update({
      friends: firebase.firestore.FieldValue.arrayUnion(inviterId),
      friendPoints:firebase.firestore.FieldValue.increment(1000),
      points:firebase.firestore.FieldValue.increment(1000)
    });
    console.log(`User ${userId} updated successfully with inviter ${inviterId}.`);
  } catch (error) {
    console.error('Error updating inviter or user:', error);
  }
}

function isSameDay(d1, d2) {
  return d1.toDateString() === d2.toDateString();
}

function addDays(date, days) {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export default App;