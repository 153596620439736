import React, { useState, useEffect, useCallback, useRef, useReducer } from 'react';
import { useTonWallet, TonConnectButton, useTonAddress , useTonConnectUI } from '@tonconnect/ui-react';
import TonWeb from 'tonweb';
import { db, firebase } from './firebase';
import {  updateDoc, arrayUnion,arrayRemove  } from 'firebase/firestore';
import { motion } from 'framer-motion';
import './Character.css';
import useSound from 'use-sound';
import StatBar from './components/StatBar'; // 引入 ExperienceBar 组件
import { characters, skin } from './CharacterDatabase';
import { beginCell,Cell } from 'ton';
import { DotLottiePlayer } from '@dotlottie/react-player';

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_SELECTED_CHARACTER':
      return { ...state, selectedCharacter: action.payload };
    case 'SET_SELECTED_SKIN':
      return { ...state, selectedSkin: action.payload };
    case 'SET_AVAILABLE_CHARACTERS':
      return { ...state, availableCharacters: action.payload };
    case 'SET_AVAILABLE_SKINS':
      return { ...state, availableSkin: action.payload };
    case 'SET_DAY_NIGHT':
      return { ...state, dayNight: action.payload };
    case 'SET_ICON_URL':
      return { ...state, iconUrl: action.payload };
    case 'SET_SHOW_BUY':
      return { ...state, showBuy: action.payload };
    case 'SET_BUYING_SKIN':
      return { ...state, buyingSkin: action.payload };
    case 'SET_TRANSACTION_STATUS':
      return { ...state, transactionStatus: action.payload };
    case 'SET_IS_BUYING':
      return { ...state, isBuying: action.payload };
    case 'SET_MESSAGE':
      return { ...state, message: action.payload };
    case 'SET_POINTS':
      return { ...state, points: action.payload };
    case 'SET_SHOW_AIRDROP':
      return { ...state, showAirdrop: action.payload };
    default:
      return state;
  }
};

const Character = React.memo(({ pointsLeaderboard = [], maxCombosLeaderboard = [] }) => {
  const [state, dispatch] = useReducer(reducer, {
    selectedCharacter: 1,
    selectedSkin: 101,
    availableCharacters: [1],
    availableSkin: [101],
    dayNight: 'day',
    iconUrl: '/asset/placeholder.png',
    showBuy: false,
    showAirdrop: false,
    buyingSkin: null,
    transactionStatus: '',
    isBuying: false,
    points: 0, // 初始化 points
  });

  const wallet = useTonWallet();
  const [username, setUsername] = useState('');

  const [playClickSound] = useSound(`${process.env.PUBLIC_URL}/asset/click.mp3`, { volume: 0.5 });
  //const [playSlideSound] = useSound(`${process.env.PUBLIC_URL}/asset/click2.mp3`, { volume: 0.3 });

  const characterSelectionRef = useRef(null);
  const characterCardsRef = useRef([]);
  const [pendingTransfer,SetPendingTransfer] = useState([]);
  const lastPlayedCharacter = useRef(null);

  const TonWeb = require('tonweb');
  const [tonConnectUI] = useTonConnectUI();
  //const userWalletAddress = tonConnectUI.wallet?.account.address;
  const userWalletAddress = useTonAddress();
  const ourWalletAddress = 'UQC0f1V-azUQcQU6EGweXYpjx6BKUQRgOvlkCsNYfJ7qursl';

  const handleClick = () => {
    playClickSound();
    if (window.Telegram && window.Telegram.WebApp) {
      window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
    }
  };

  //------------------彈出式訊息----------------------
  const showMessage = useCallback((msg, duration = 2000) => {
    dispatch({ type: 'SET_MESSAGE', payload: msg });
    if (window.messageTimeout) {
      clearTimeout(window.messageTimeout);
    }
    window.messageTimeout = setTimeout(() => dispatch({ type: 'SET_MESSAGE', payload: '' }), duration);
  }, []);

  //------------------初始化用戶數據----------------------
  useEffect(() => {
    const fetchUserData = async () => {
      const userId = window.Telegram.WebApp.initDataUnsafe.user.id.toString();
      const userRef = db.collection('users').doc(userId);
      console.log("Try access firebase(Read)")
        const doc = await userRef.get();
        if (doc.exists) {
          const userData = doc.data();

          dispatch({ type: 'SET_POINTS', payload: userData.points || 0 });
          setUsername(userData.username || '');
          dispatch({ type: 'SET_SELECTED_CHARACTER', payload: userData.selectedCharacter || 1 });
          dispatch({ type: 'SET_SELECTED_SKIN', payload: userData.skin || 101 });
          dispatch({ type: 'SET_AVAILABLE_CHARACTERS', payload: userData.availableCharacters || [1] });
          dispatch({ type: 'SET_AVAILABLE_SKINS', payload: userData.availableSkins || [101] });
          dispatch({ type: 'SET_DAY_NIGHT', payload: userData.dayNight || 'day' });
          userData.dayNight === 'day' ? dispatch({ type: 'SET_ICON_URL', payload: skin.find(skin => skin.id === userData.skin)?.dayUrl }) : dispatch({ type: 'SET_ICON_URL', payload: skin.find(skin => skin.id === userData.skin)?.nightUrl });
        
          if(userData.pendingTransfer)
            {
              const pendingTransfer = userData.pendingTransfer;
              SetPendingTransfer(pendingTransfer);
              console.log("pendingTransfer");
              console.log(pendingTransfer);
            }
            else
            {
              userRef.update({ pendingTransfer: [] });
            }
        }
      }
    fetchUserData();
  }, []); // 依赖项为空数组，确保只在组件挂载时执行一次

  //------------------選擇角色系列----------------------
  const handleCharacterSelect = useCallback((character, index) => {
    // 滚动到角色并置中
    if (characterCardsRef.current[index]) {
      characterCardsRef.current[index].scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center'
      });
    }
  
    // 仅在角色可用时设置选中角色
    if (state.availableCharacters.includes(character.id)) {
      dispatch({ type: 'SET_SELECTED_CHARACTER', payload: character.id });
      const availableSkinsForCharacter = skin.filter(s => s.character === character.id && state.availableSkin.includes(s.id));
      const availableSkinsForCharacterIds = availableSkinsForCharacter.map(skin => skin.id);
      if (!availableSkinsForCharacterIds.includes(state.selectedSkin)) {
        dispatch({ type: 'SET_SELECTED_SKIN', payload: availableSkinsForCharacter[0].id });
      }
    } else {
      showMessage('Character not available');
    }
  }, [state.availableCharacters, showMessage ,state.availableSkin ,state.selectedSkin]);

  const handleSkinSelect = useCallback(async (skin) => {
    if(state.availableCharacters.includes(skin.character)){
      if (state.availableSkin.includes(skin.id)) {
        dispatch({ type: 'SET_SELECTED_SKIN', payload: skin.id });
      } else {
        if (skin.unlockMethod === 'mission') {
          showMessage('You need to complete the mission to unlock this skin');
        } else if (skin.unlockMethod === 'purchase') {
        dispatch({ type: 'SET_SHOW_BUY', payload: true });
          dispatch({ type: 'SET_BUYING_SKIN', payload: skin.id });
        }
      }
    }
  }, [state.availableSkin, showMessage , dispatch, dispatch]);

  const setCharacter = useCallback(async () => {
    const userId = window.Telegram.WebApp.initDataUnsafe.user.id.toString();
    const userRef = db.collection('users').doc(userId);
    await userRef.update({ selectedCharacter: state.selectedCharacter, skin: state.selectedSkin });
    showMessage('Character selected successfully');
    state.dayNight === 'day' ? dispatch({ type: 'SET_ICON_URL', payload: skin.find(skin => skin.id === state.selectedSkin)?.dayUrl }) : dispatch({ type: 'SET_ICON_URL', payload: skin.find(skin => skin.id === state.selectedSkin)?.nightUrl });
    //setIconUrl(skin.find(skin => skin.id === selectedSkin)?.url);
  //}, [state.selectedCharacter, state.selectedSkin, showMessage, playClickSound]); // 添加赖项数组
  }, [state.selectedCharacter, state.selectedSkin, showMessage]); // 添加依赖项数组

  const updateUserSkins = async (newSkinId) => {
    const userId = window.Telegram.WebApp.initDataUnsafe.user.id.toString();
    const userRef = db.collection('users').doc(userId);
    
    try {
      await updateDoc(userRef, {
        availableSkins: arrayUnion(newSkinId)
      });
      
      dispatch({ type: 'SET_AVAILABLE_SKINS', payload: [...state.availableSkin, newSkinId] });
      showMessage('新皮膚已解！');
      
      dispatch({ type: 'SET_SELECTED_SKIN', payload: newSkinId });
    } catch (error) {
      console.error('更新用戶皮膚時出錯:', error);
      showMessage('解鎖新皮膚時出錯。請重試。');
    }
  };

  useEffect(() => {
    const observerOptions = {
      root: characterSelectionRef.current,
      rootMargin: '0px',
      threshold: 0.5 // 50% of the target's visibility
    };

    const observerCallback = (entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const index = characterCardsRef.current.indexOf(entry.target);
          const character = characters[index];

          if (character && state.availableCharacters.includes(character.id) && character.id !== lastPlayedCharacter.current) {
            dispatch({ type: 'SET_SELECTED_CHARACTER', payload: character.id });

            const availableSkinsForCharacter = skin.filter(s => s.character === character.id && state.availableSkin.includes(s.id));
            if (availableSkinsForCharacter.length > 0) {
              dispatch({ type: 'SET_SELECTED_SKIN', payload: availableSkinsForCharacter[0].id });
            }
            lastPlayedCharacter.current = character.id;
            // debouncedPlaySlideSound(); // Uncomment if you want to play sound
          }
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    characterCardsRef.current.forEach(card => {
      if (card) {
        observer.observe(card);
      }
    });

    return () => {
      observer.disconnect();
    };
  }, [state.availableCharacters, state.availableSkin]);

  //-------------------------------------------交易系列-------------------------------------------

const createCommentPayload = (comment) => {
  try {
    const body = beginCell()
        .storeUint(0, 32)  // 评论的操作码
        .storeStringTail(comment)  // 存储评论
        .endCell();
    return body.toBoc().toString('base64');
  }catch (error) {
    console.error('Error create payload:', error);
    return null;
  }
};

  const handleBuy = async () => {
    const skinToBuy = skin.find(s => s.id === state.buyingSkin);
    if (!skinToBuy) {
      showMessage('錯誤：未找到皮膚');
      return;
    }
    try {
      console.log('Initiating transfer...');
      console.log('TonConnectUI state:', tonConnectUI);

      if (!tonConnectUI) {
        throw new Error('TON Connect UI is not initialized');
      }

      if (!wallet) {
        showMessage('Please connect your TON wallet first.');
        return;
      }

      tonConnectUI.setConnectRequestParameters({ state: 'loading' });

      const transaction = {
        validUntil: Math.floor(Date.now() / 1000) + 60 * 20,
        messages: [
          {
            address: ourWalletAddress,
            amount: (skinToBuy.price * 1e9).toString(),
            payload: createCommentPayload(`buy_skin:${state.buyingSkin}`)
          }
        ]
      };

      const result = await tonConnectUI.sendTransaction(transaction);
      console.log('transaction result:', JSON.stringify(result));; // 添加日志检查交易结果
      dispatch({ type: 'SET_TRANSACTION_STATUS', payload: '交易已發送。等待確認...' });
      console.log('交易已發送。等待確認...');

      const userId = window.Telegram.WebApp.initDataUnsafe.user.id.toString();
      const userRef = db.collection('users').doc(userId);
      await userRef.update({ pendingTransfer: firebase.firestore.FieldValue.arrayUnion(state.buyingSkin) });

      SetPendingTransfer([...pendingTransfer, state.buyingSkin]);
      
      await checkTransactionStatus(state.buyingSkin);
    } catch (error) {
      console.error('購買時出錯:', error);
      dispatch({ type: 'SET_TRANSACTION_STATUS', payload: `錯誤：${error.message}。請重試。` });
    } finally {
      dispatch({ type: 'SET_IS_BUYING', payload: false });
    }
  };
  
  const checkTransactionStatus= async(buyingSkin)=> {
    console.log("WalletAddress:"+userWalletAddress)
    console.log(`buy_skin:${buyingSkin}`)
    const targetSkin = skin.find(s => s.id === buyingSkin);
    const skinPrice = targetSkin.price;
    const minTransactionAmount = skinPrice*1e9*0.095;
    try {
      const response = await fetch(`https://toncenter.com/api/v2/getTransactions?address=${userWalletAddress}&limit=10&to_lt=0&archival=true`, {
        method: 'GET',
        headers: {
          'accept': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      const transactions = data.result || []; // 确保 transactions 是一个数组

      console.log(transactions);

       const filteredTransactions = transactions.filter(tx => {
        return tx.out_msgs[0]
        && (tx.out_msgs[0].message === `buy_skin:${buyingSkin}`|| tx.in_msg.message === `buy_skin:${buyingSkin}`)
        && parseInt(tx.out_msgs[0].value) > minTransactionAmount;
        });
        console.log('符合條件的交易記錄:', filteredTransactions);
        if(filteredTransactions.length > 0)
        {
         console.log("Success")
         showMessage("Purchase success!");
         await updateUserSkins(buyingSkin);
         dispatch({ type: 'SET_TRANSACTION_STATUS', payload: 'Transaction successful! Skin unlocked.' });
          dispatch({ type: 'SET_SHOW_BUY', payload: false });
          const userId = window.Telegram.WebApp.initDataUnsafe.user.id.toString();
          const userRef = db.collection('users').doc(userId);
      await userRef.update({ pendingTransfer: firebase.firestore.FieldValue.arrayRemove(state.buyingSkin) });
         return;
        }else{
          console.log("Not found");
          showMessage("Transaction not found,please try again later or comfirm you have already purchase");
        }
    
} catch (error) {
    console.error('獲取交易記錄時發生錯誤:', error);
    showMessage("Transaction not found,please try again later or comfirm you have already purchase");
}
};
 //-----------------------------------------------AirDrop系列--------------------------------------------------------
 
  // 计算用户的奖励
  const calculateRewards = () => {
    const userId = window.Telegram.WebApp.initDataUnsafe.user.id.toString();
    // 获取用户的排行榜排名
    const pointsRank = pointsLeaderboard.findIndex(user => user.userId === userId) + 1;
    const combosRank = maxCombosLeaderboard.findIndex(user => user.userId === userId) + 1;
    console.log("pointsRank:"+pointsRank + ",combosRank:"+combosRank)
    // 计算排行榜CATZ数
    const calculateLeaderboardCatz = (rank) => {
      if (rank >= 1 && rank <= 3) return 1000000;
      if (rank >= 4 && rank <= 10) return 500000;
      if (rank >= 11 && rank <= 100) return 100000;
      return 0;
    };

    const pointsCatz = calculateLeaderboardCatz(pointsRank);
    const combosCatz = calculateLeaderboardCatz(combosRank);

    // 计算一般CATZ数
    const generalCatz = state.points;

    // 确定NFT类型
    const calculateNftType = (rank) => {
      if (rank >= 1 && rank <= 3) return 'Mystic NFT *1';
      if (rank >= 4 && rank <= 10) return 'EPIC NFT *1';
      if (rank >= 11 && rank <= 100) return 'Rare NFT *1';
      if (rank >= 100) return 'None';
      return null;
    };

    const pointsNft = calculateNftType(pointsRank);
    const combosNft = calculateNftType(combosRank);

    // 检查是否有 blind box NFT
    const blindBoxNftCount = state.availableSkin.filter(skinId => ![101, 201, 301].includes(skinId)).length;

    return (
      
      <div>
        <h2>Your Airdrop Rewards</h2>
        <div className='Airdrop-content'>      
        <div className='Airdrop-item'>
          <div>$CATZ: </div>
          <div>{generalCatz}</div>
        </div>
        $CATZ Rewards:
        <div className='Airdrop-item'>
          <div>Ranking in points: </div>
          <div>{pointsCatz}</div>
        </div>
        <div className='Airdrop-item'>
          <div>Ranking in combos: </div>
          <div>{combosCatz}</div>
        </div>          
        <div className='Airdrop-item Total'>
          <div>Total $CATZ: </div>
          <div>{generalCatz + pointsCatz + combosCatz}</div>
        </div>
        </div>
        <div className='Airdrop-content'>
        NFT Rewards:
          <div className='Airdrop-item'>
            <div>Skins:</div>
            <div>Blind box NFT *{blindBoxNftCount}</div>
          </div>
          <div className='Airdrop-item'>
            <div>Ranking in points:</div>
            <div> {pointsNft}</div>
          </div>
          <div className='Airdrop-item'>
            <div>Ranking in combos:</div>
            <div>{combosNft}</div>
          </div>
      </div>
      </div>
    );
    };

 //-----------------------------------------------渲染--------------------------------------------------------

  return (
    <>    
    {state.message && <div className="message">{state.message}</div>}
    <div className="profile-container">
      <div className="profile-content">
        <img 
          src={state.iconUrl} 
          alt="character" 
          className="current-character-thumbnail"
        />
        <div className="user-infoc">
        <div className="username1">{username}</div>
            <div className="total-score">
              $CATZ: {state.points}
              <img alt="coin" src={`${process.env.PUBLIC_URL}/asset/Catz_s.webp`} style={{ width: '5vw', height: 'auto' }}/>
              </div>
        </div>
        <TonConnectButton />
      </div>
    </div>

    <div className="character-container">
    <div className="character-background">
      <div className="character-content">
        <div className="character-selection" ref={characterSelectionRef}>
          {characters.map((character, index) => {
            const isAvailable = state.availableCharacters.includes(character.id);
            const skins = skin.filter(s => s.character === character.id) || [];

            const defaultSkin = skins[0] || character || {};
            const currentSkin = skins.find(s => s.id === state.selectedSkin) || defaultSkin || {};
            
            return (
              <motion.div
                key={character.id}
                ref={el => characterCardsRef.current[index] = el}
                className={`character-card ${state.selectedCharacter === character.id ? 'selected' : ''} ${isAvailable ? '' : 'unavailable'}`}
                onClick={() => handleCharacterSelect(character,index)}
              >
                <div className="character-information">
                <div className="character-image">
                  
                  <img 
                    loading="lazy"
                    src={state.dayNight === 'day' ? currentSkin.dayUrl : currentSkin.nightUrl || '/asset/placeholder.png'} 
                    alt={`角色 ${character.id || ''}`} 
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = '/asset/placeholder.png';
                    }}
                  />
                  <div className="character-name">{currentSkin.name}</div>
                  {!isAvailable && <div className="locked-overlay">🔒</div>}
                  
                </div>
                <div className="character-properties">
                  <div className="property-slider">
                    <label>Bounce</label>
                    <StatBar value={character.Bounce} max={5} />
                  </div>
                  <div className="property-slider">
                    <label>Spin</label>
                    <StatBar value={character.Spin} max={5} />
                  </div>
                  <div className="property-slider">
                    <label>Size</label>
                    <StatBar value={character.Size} max={5} />
                  </div>
                  <div className="property-slider">
                    <label>Power</label>
                    <StatBar value={character.Power} max={5} />
                  </div>
                  <div className="property-slider">
                    <label>Speed</label>
                    <StatBar value={character.Speed} max={5} />
                  </div>
                </div>
                </div>
                <div className="character-skin-container">
                  {skins.map((skinItem, skinIndex) => {
                    const isAvailableSkin = state.availableSkin.includes(skinItem.id);
                    return (
                      <div 
                        key={skinIndex} 
                        className={`character-skin ${state.selectedSkin === skinItem.id ? 'selected' : ''} ${isAvailableSkin ? '' : 'unavailable'}`}
                      >
                        <img 
                          src={state.dayNight === 'day' ? skinItem.dayUrl : skinItem.nightUrl || '/asset/placeholder.png'} 
                          alt={`Skin ${skinIndex}`}
                          onClick={() => handleSkinSelect(skinItem)}
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = '/asset/placeholder.png';
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              </motion.div>
            );
          })}
          {/* 新增的 character-card */}
          <motion.div className="GetNFT-card">
          <img src={`${process.env.PUBLIC_URL}/asset/gif/GetNFT.gif`} alt="character" className="GetNFT-Gif"/>
            <button className="airdrop-button" onClick={() => { handleClick(); dispatch({ type: 'SET_SHOW_AIRDROP', payload: true }); }}>
              Check Airdrop
            </button>          
          </motion.div>
        </div>
        <button
          className="select-button"
          onClick={() => {
            handleClick();
            setCharacter();
          }}
          disabled={!state.selectedCharacter}
        >
          COMFIRM
        </button>
      </div>     
    </div>
    </div>
    {state.showBuy && (
      <div className="buying-window">
        <div className="buying-window-content">
          <button className="close-button" onClick={() => { 
            handleClick();
            dispatch({ type: 'SET_SHOW_BUY', payload: false });
          }}>
            X
          </button>
          <h2 className="character-name">{skin.find(s => s.id === state.buyingSkin)?.name}</h2>
          <img 
            className="skin-image"
            src={state.dayNight === 'day' ? skin.find(s => s.id === state.buyingSkin)?.dayUrl : skin.find(s => s.id === state.buyingSkin)?.nightUrl} 
            alt="skin" 
          />
          <p className="price">
            Price: {skin.find(s => s.id === state.buyingSkin)?.price} 
            <i className="fas fa-paper-plane ton-icon"></i> TON
          </p>
          {!wallet ? (
            <>
            Please connet wallet first
            </>
          ) : (
            <>
            <button className="buyWindow-button" onClick={() => {
              handleClick();
              handleBuy();
            }} disabled={state.isBuying}>
              {state.isBuying ? 'On Progress...' : 'Buy'}
            </button>
            {pendingTransfer.includes(state.buyingSkin) && (
              <button 
                className="buyWindow-button" 
                onClick={() => {
                  handleClick();
                  checkTransactionStatus(state.buyingSkin);
                }}
              >
                Check
              </button>
            )}
           </>
          )}
        </div>
      </div>
    )}

    {state.showAirdrop && (
      <div className="Airdrop-window">
        <div className="Airdrop-window-content">          
          {calculateRewards()}
          <button className="Airdrop-close-button" onClick={() => {
            handleClick();
            dispatch({ type: 'SET_SHOW_AIRDROP', payload: false });
          }}>
            Cool!
          </button>
        </div>
      </div>
    )}
    </>
  );
});

export default Character;
